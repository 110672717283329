import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { register } from './serviceWorkerRegistration'
import messenger from 'libs/messenger'

const currentVersion = "3.0.0-b.1"

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('app-root')
)

register({
  currentVersion: currentVersion,
  onWaiting: () => window.location.reload(),
  onRegister: () => {
    // Check version
    const oldVersion = localStorage.getItem("currentVersion")
    if (currentVersion !== oldVersion) {
      localStorage.setItem("currentVersion", currentVersion)
      if (oldVersion) {
        messenger.showInfo("New Version Installed", `Welcome to EventOPS v${currentVersion}`, 5)
      }
    }
  },
})