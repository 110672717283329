import React from 'react'
import { Link, LinkBack } from 'libs/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import './Content.scss'
import { ProgressSpinner } from 'primereact/progressspinner'


export function ContentHeader({
  back,
  title,
  buttons
}) {

  const backButton = !back ? null : (
    back === true ?
      <LinkBack className="back-button">
        <FontAwesomeIcon icon={faChevronLeft} />
      </LinkBack>
      :
      <Link className="back-button" to={back}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Link>
  )

  return (
    <div className="app-layout-content-header custom-main-header">
      <div className="content-header-title">
        {backButton}
        {title}
      </div>
      <div className="content-header-buttons">{buttons}</div>
    </div>
  )
}

ContentHeader.defaultProps = {
  back: false, // If true, will navigate back. If a string, will navigate to the string (URL path).
  title: 'No Title',
  buttons: null,
}

export function ContentMain({
  maxWidth,
  noWrapper,
  children,
}) {

  return (
    <main className="app-layout-content custom-main">
      {noWrapper ?
        children
        :
        <div className="content-wrapper">
          {React.Children.map(children, (child, index) => React.isValidElement(child) && (
            <div key={index} className="content custom-content" style={{ maxWidth: !maxWidth ? "unset" : maxWidth === true ? "1200px" : maxWidth }}>
              {child}
            </div>
          ))}
        </div>
      }
    </main>
  )
}

ContentMain.defaultProps = {
  maxWidth: false, // Boolean or String as "1200px"
  noWrapper: false,
  children: null,
}

export default function Content({
  back,
  title,
  buttons,
  maxWidth,
  noWrapper,
  children,
  inIframe,
}) {
  return (
    <>
      {!inIframe ? (
        <ContentHeader
          back={back}
          title={title}
          buttons={buttons}
        />
      ) : null}
      <ContentMain
        maxWidth={maxWidth}
        noWrapper={noWrapper}
        children={children}
      />
    </>
  )
}