import { copyDeep } from './objectHandlers'

export function sortValues(key = 'label') {
  return (a, b) => {
    // Get values to compare
    let valueA = a[key]
    let valueB = b[key]
    if (typeof valueA === 'string') {
      valueA = valueA.toUpperCase()
    }
    if (typeof valueB === 'string') {
      valueB = valueB.toUpperCase()
    }
    // Compare values
    let comparison = 0
    if (valueA > valueB) {
      comparison = 1
    } else if (valueA < valueB) {
      comparison = -1
    }
    // Return result
    return comparison
  }
}

export function sortArrayByKey(array, key = 'name') {
  if (!(array instanceof Array)) { ////if (!Array.isArray(array)) {
    return []
  }
  return copyDeep(array).sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}
