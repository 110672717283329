import React from 'react'
import BaseField from './BaseField'


export default function FieldHidden(props) {
  /* Input Hidden
   * Expected Props:
   * label = <string> Required
   * value = <string> Required
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */

  // RENDER
  return (
    <BaseField
      className="field-hidden" //border border-secondary"
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        <input
          className="form-control custom-field-input"
          type="text"
          value={props.value}
          disabled={true}
        />
      }
      help="This is a hidden field and will not be shown on the form - it is shown here for debug and planning purposes only."
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
