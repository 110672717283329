import React from 'react'
import Content from 'components/layout/Content'
import Form from './Form'
import Loading from 'apps/loading'
import NotFound from 'apps/notfound'


export default function FormApp(props) {
  //props.data = {
  //  'can_be_saved': <Boolean>,
  //  'title': <String>,
  //  'intro': <String>,
  //  'fields': <Array>,
  //  'style': <Object>,
  //  'submitted_title': <String>,
  //  'submitted_content': <String>,
  //}

  if (!Array.isArray(props.data?.fields)) {
    return props.isLoading ? < Loading /> : <NotFound />
  }

  const user = props.account
  const title = props.data.title
  const intro = props.data.intro
  const form = props.data

  return (
    <Content title={title} inIframe={props.inIframe} maxWidth>
      <Form
        user={user}
        intro={intro}
        form={form}
        isFormValid={true}
      />
    </Content>
  )
}
