import React from 'react'
import { default as AntdTag } from 'antd/es/tag'
import Select from 'antd/es/select'
import { getRandomColor, isColorLight } from 'libs/utils'


// TagSelect Example
// const [tags, setTags] = React.useState(() => [{ name: "Tag 1", color: "#0fe35d" }, { name: "Tag 2", color: "#eaa221" }])
// return (
//   <TagSelect
//     tags={tags}
//     setTags={setTags}
//     defaultTags={[{ name: "Tag 2", color: "#fef6ff" }, { name: "Tag 3", color: "#000000" }]}
//   />
// )
export function TagSelect({ tags, setTags, defaultTags, disabled }) {
  const options = React.useMemo(() => {
    const newOptions = {}
    defaultTags?.forEach(tag => {
      if (!(tag.name in newOptions)) {
        newOptions[tag.name] = { name: tag.name, color: tag.color }
      }
    })
    tags.forEach(tag => {
      if (!(tag.name in newOptions)) {
        newOptions[tag.name] = { name: tag.name, color: tag.color }
      }
    })
    return newOptions
  }, [JSON.stringify(tags), JSON.stringify(defaultTags)])

  const onChange = values => {
    setTags(values.map(value => ({ name: value, color: options[value]?.color })))
  }

  const tagRender = ({ label, value, closable, onClose }) => {
    const color = options[value]?.color
    return <AntdTag
      color={color}
      closable={closable}
      onClose={onClose}
      style={{ color: isColorLight(color) ? "#000000" : "#ffffff" }}
    >
      {label}
    </AntdTag>
  }

  return (
    <Select
      showArrow
      allowClear
      mode="multiple"
      disabled={disabled}
      notFoundContent=""
      value={tags.map(tag => tag.name)}
      onChange={onChange}
      style={{ width: '100%' }}
      tagRender={tagRender}
      options={
        Object.keys(options)
          .filter(tag => !tags.some(t => t.name === tag))
          .map(tag => ({ label: tag, value: tag }))
      }
    />
  )
}

export function TagSelectTags({ tags, setTags, defaultTags }) {
  const options = React.useMemo(() => {
    const newOptions = {}
    defaultTags?.forEach(tag => {
      if (!(tag.name in newOptions)) {
        newOptions[tag.name] = { name: tag.name, color: tag.color }
      }
    })
    tags.forEach(tag => {
      if (!(tag.name in newOptions)) {
        newOptions[tag.name] = { name: tag.name, color: tag.color }
      }
    })
    return newOptions
  }, [JSON.stringify(tags), JSON.stringify(defaultTags)])

  const onChange = values => {
    setTags(values.map(value => ({ name: value, color: options[value]?.color })))
  }

  const tagRender = ({ label, value, closable, onClose }) => {
    const color = options[value]?.color
    return <AntdTag
      color={color}
      closable={closable}
      onClose={onClose}
      style={{ color: color !== undefined ? (isColorLight(color) ? "#000000" : "#ffffff") : '#000000'}}
    >
      {label}
    </AntdTag>
  }

  return (
    <Select
      showArrow
      allowClear
      mode="tags"
      notFoundContent=""
      value={tags.map(tag => tag.name)}
      onChange={onChange}
      style={{ width: '100%' }}
      tagRender={tagRender}
      options={
        Object.keys(options)
          .filter(tag => !tags.some(t => t.name === tag))
          .map(tag => ({ label: tag, value: tag }))
      }
    />
  )
}

export default function Tag({
  name,
  color,
  setColor
}) {
  const createColor = () => {
    const newColor = getRandomColor()
    if (typeof setColor === "function") {
      setColor(newColor)
    }
    return newColor
  }
  const [savedColor, setSavedColor] = React.useState(color || createColor())
  const [textColor, setTextColor] = React.useState(isColorLight(color) ? "#000000" : "#ffffff")

  React.useEffect(() => {
    setSavedColor(oldSavedColor => {
      if (color) {
        return color
      }
      if (!oldSavedColor) {
        return createColor()
      }
      return oldSavedColor
    })
  }, [color])

  React.useEffect(() => {
    setTextColor(isColorLight(savedColor) ? "#000000" : "#ffffff")
  }, [savedColor])


  return (
    name && <AntdTag color={savedColor} style={{ color: textColor }}>{name}</AntdTag>
  )
}

Tag.defaultProps = {
  name: "",
  color: "",
  setColor: undefined, // a function to set color
}
