import React from 'react'
import { Result } from 'antd'
import Content, { ContentMain } from 'components/layout/Content'
import './index.scss'


function NotFoundContent(props) {

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}

export function NotFoundRoot(props) {

  return (
    <ContentMain>
      <NotFoundContent />
    </ContentMain>
  )
}

export default function NotFound(props) {

  return (
    <Content title="Page not found">
      <NotFoundContent />
    </Content>
  )
}
