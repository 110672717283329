import React, {useState, useEffect} from 'react'
import api from 'libs/api'

export default function RegistrationForm({
    eventId,
}) {

//   const [image, setImage] = useState([])

  // const onSave = async (eventUUID, micrositeUUID, micrositeData) => {
  //   let read = await api.patch('api/event/' + eventUUID + '/marketing/microsite/' + micrositeUUID, {
  //     name: micrositeData.name,
  //     html: micrositeData.html,
  //     design_json: micrositeData.design_json,
  //     expiration_html: micrositeData.expiration_html,
  //     expiration_design_json: micrositeData.expiration_design_json,
  //     expiration_date: micrositeData.expiration_date ? micrositeData.expiration_date : null,
  //   })
  //     .then(([success, response]) => {
  //       if (success) {
  //         return response.data
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  //   return read
  // }

  useEffect(() => {

  }, [])

  return <>SOMETHING</>
}
