import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { Spin } from 'antd'
import './index.scss'


export function Spinner(props) {
  return <FontAwesomeIcon className="spinner" icon={faSpinnerThird} spin />
}

export function ContentSpinner({ className, spinning, children }) {
  return (
    <Spin
      wrapperClassName={"content-spinner" + (className ? " " + className : "")}
      indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
      spinning={spinning}
      delay={500}
    >
      {children}
    </Spin>
  )
}

ContentSpinner.defaultProps = {
  spinning: false,
  children: null
}
