import React, {useState, useEffect} from 'react'
import useCalendar from 'components/calendar'
import api from 'libs/api'
import { navigateTo } from 'libs/utils'

export default function SessionCalendarView({
    eventId,props,fullEventData
}) {

  const [eventData, setEventData] = useState([])

  const getCalendarData = async () => {
    let read = await api.get('api/event/' + eventId + '/program/session/?page_size=1000')
      .then(([success, response]) => {
        if (success) {
          // console.log(response.data)
          return response.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    return read
  }

  const [
    calendar,
    monthQuery,
    resourceCalendar,
  ] = useCalendar({
    data: eventData,
    eventId:eventId,
    fullEventData:fullEventData,
    startDateFieldName: 'start_datetime',
    endDateFieldName: 'end_datetime',
    popoverFieldNames: [{label: 'Name', value: 'name'}, {label: 'Agenda', value: 'agenda'}],
    eventClick: e => navigateTo('event/' + e.event.id + '/'),
    setIsLoading: props.setIsLoading,
  })

  useEffect(() => {
    getCalendarData().then(res => {
      setEventData(res)
    })
  }, [])

  return (
    <div className="col-12 p-3 h-100">
      {resourceCalendar}
    </div>)
}
