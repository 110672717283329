import { useContext, useMemo } from 'react'
import moment from 'moment-timezone'
import DatePicker from 'antd/es/date-picker'
import { momentLocalDateFormat, momentLocalTimeFormat, range } from 'libs/utils'
import { SettingsContext } from 'App'
import BaseField from './BaseField'

export const changeTimeZone = (date, timeZone) => {
  if (typeof date === "string") {
    return new Date(new Date(date).toLocaleString("en-US", { timeZone }))
  }
  return new Date(date.toLocaleString("en-US", { timeZone }))
}


export default function FieldDatetime(props) {
  /* Input Datetime
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * timezone = <timezone> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   * minuteStep = <int> Optional (Default = 5)
   */
  const useTime = !props.type?.endsWith("date")
  const ignoreOffset = props.type === "date"
  const toString = value => (useTime ? value?.seconds(0).milliseconds(0).toISOString() : value?.toISOString(ignoreOffset).substr(0, 10)) || null
  const fromString = value => !value ? "" : props.timezone ? moment.tz(value, props.timezone) : moment(value)

  // Memos
  const date = useMemo(() => fromString(props.value), [props.value, props.timezone])

  const minDate = useMemo(() => fromString(props.min), [props.min, props.timezone])

  const maxDate = useMemo(() => fromString(props.max), [props.max, props.timezone])

  const timezoneAbbr = useMemo(() => {
    if (date && props.timezone && useTime && date.utcOffset() !== moment().utcOffset()) {
      return isNaN(date.zoneAbbr()) ? " z" : ` [GMT${date.utcOffset() >= 0 ? "+" : ""}${(date.utcOffset() / 60).toFixed(2)}]`
    }
    return ""
  }, [date, props.timezone, useTime])

  const companySettings = useContext(SettingsContext)
  const defaultTime = useMemo(() => {
    if (date) return date
    const value = `${props.name?.startsWith("start") ? companySettings.default_start_hour : companySettings.default_end_hour}:00`
    return props.timezone ? moment.tz(value, "H:00", props.timezone) : moment(value, "H:mm")
  }, [date, props.timezone])

  // Functions
  const handleChange = (nextDate) => {
    if (props.timezone) {
      nextDate?.tz(props.timezone, true)
    }
    props.onChange({ target: { value: toString(nextDate) } })
  }

  // - Check min/max date
  const disabledDate = (date) => {
    if (!minDate && !maxDate) return false
    const dateString = date.toISOString(true)?.slice(0, 10)
    return (minDate && dateString < minDate.toISOString(true)?.slice(0, 10)) || (maxDate && dateString > maxDate.toISOString(true)?.slice(0, 10))
  }

  // - Check min/max time
  const getDateValue = (date) => date ? date.toISOString(true)?.slice(0, 10) : null
  const getHourValue = (date) => date ? Number(date.toISOString(true)?.slice(11, 13)) : null
  const getMinuteValue = (date) => date ? Number(date.toISOString(true)?.slice(14, 16)) : null
  const disabledTime = (date) => {
    return {
      disabledHours: () => {
        if (!date) return []
        const minHour = minDate && getDateValue(date) === getDateValue(minDate) ? getHourValue(minDate) : 0
        const maxHour = maxDate && getDateValue(date) === getDateValue(maxDate) ? getHourValue(maxDate) : 23
        return range(0, 23).filter(h => h < minHour || h > maxHour)
      },
      disabledMinutes: (hour) => {
        if (!date) return []
        if (hour < 0) return range(0, 59)
        const minMinute = minDate && getDateValue(date) === getDateValue(minDate) &&
          getHourValue(date) === getHourValue(minDate) ? getMinuteValue(minDate) : 0
        const maxMinute = maxDate && getDateValue(date) === getDateValue(maxDate) &&
          getHourValue(date) === getHourValue(maxDate) ? getMinuteValue(maxDate) : 59
        return range(0, 59).filter(m => m < minMinute || m > maxMinute)
      },
    }
  }
  
  //fn called when a date is selected
  const updateDateWhenClicked =(e)=>{
    props.onChange({ target: { value: toString(e) } })
  }


  // Render
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        <DatePicker
          ref={props.inputRef}
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          showTime={useTime && {
            defaultValue: defaultTime,
            format: momentLocalTimeFormat,
            minuteStep: props.minuteStep || 5
          }}
          format={momentLocalDateFormat + (useTime ? ` @ ${momentLocalTimeFormat}${timezoneAbbr}` : "")}
          placeholder={props.readOnly ? "" : props.placeholder}
          value={date}
          onChange={handleChange}
          onBlur={props.onBlur}
          disabled={props.disabled || props.readOnly}
          inputReadOnly={props.readOnly}
          disabledDate={disabledDate}
          disabledTime={disabledTime}
          picker={props?.picker || 'date'}
          open={props?.open}
          onSelect={updateDateWhenClicked}
          autoFocus={props.autoFocus}
          
        />
      }
      prepend={props.prepend}
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
