import React from 'react'
import classNames from 'classnames'
import Markdown from 'components/markdown'


export default function FieldMarkdown(props) {
  /* Input Tel
   * Expected Props:
   * className = <string> Optional
   * value = <string> Required
   */

  // RENDER
  return (
    <div className={classNames("w-100 mb-3 custom-field-label", props.className)}>
      <Markdown input={props.value} />
    </div>
  )
}
