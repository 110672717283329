import React from 'react'
import BaseField from './BaseField'
import { copyDeep, isEqual, sortArrayByKey } from 'libs/utils'


export default function FieldDatalist(props) {
  /* Input Datalist
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Optional
   * value = <string> Required
   * values = [{ label: <string>, value: <string> }] Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */

  const [value, setValue] = React.useState(props.value)
  const [prevValues, setPrevValues] = React.useState()
  const [options, setOptions] = React.useState(null)
  const selectedIndex = props.values ? props.values.findIndex(v => ("" + v.value) === props.value) : -1
  const selectedValue = (selectedIndex !== -1) ? ("" + props.values[selectedIndex].label) : value
  const listId = React.useMemo(() => props.name || Math.random().toString(16).slice(2), [props.name])

  // INITIALIZATION
  React.useEffect(() => {
    if (!isEqual(prevValues, props.values)) {
      setOptions(sortArrayByKey(props.values, "label").map((option, key) =>
        <option key={key} value={"" + option.label}>{"" + option.label}</option>
      ))
      setPrevValues(copyDeep(props.values))
    }
  }, [props.values])

  // FUNCTIONS
  const setStateValue = value => {
    props.onChange({ target: { value: value } })
  }

  const onBlur = event => {
    // Clear local value if left any invalid input
    if (value) {
      setValue("")
    }
    // Run props onBlur function if provided
    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  const onChange = event => {
    // Search inputted value in values
    const typedIndex = props.values.findIndex(
      v => ("" + v.label).toLowerCase() === event.target.value.toLowerCase()
    )
    // If it's a valid value, clear local value and save it to state value
    if (typedIndex !== -1) {
      setStateValue("" + props.values[typedIndex].value)
      setValue("")
    }
    // If it's an invalid value, clear state value and save it to local value
    else {
      if (props.value) {
        setStateValue("")
      }
      setValue(event.target.value)
    }
  }

  // VALIDATION
  React.useEffect(() => {
    // Check if state value is a valid option in values
    if (props.value !== "" && selectedIndex === -1) {
      setStateValue("")
      setValue("")
    }
  }, [props.value, selectedIndex])

  // RENDER
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        <>
          <input
            ref={props.inputRef}
            list={listId}
            className={"form-control custom-field-input" + (
              props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
            )}
            name={props.name}
            placeholder={props.placeholder}
            value={selectedValue || ""}
            required={props.required && props.valid === false}
            onChange={onChange}
            onBlur={onBlur}
            disabled={props.disabled}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
          />
          <datalist id={listId}>
            {options}
          </datalist>
        </>
      }
      prepend={props.prepend}
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
