import './index.scss'
export * from './dataCheckers'
export * from './format'
export * from './navigations'
export * from './objectHandlers'
export * from './renders'
export * from './sortValues'
export * from '@eventops/utils'

export function getRandomColor() {
  return (
    '#' +
    ('000000' + Math.floor(Math.random() * 0xffffff).toString(16)).substr(-6)
  )
}

export function getUniqueName(name, listOfNames) {
  // If name not on list
  if (!listOfNames.includes(name)) {
    return name
  }

  // Get an unique name
  const cleanedName = name.replace(/_[0-9]+$/, '')
  let nameCopies = 1
  while (listOfNames.includes(cleanedName + '_' + nameCopies)) {
    nameCopies += 1
  }
  return cleanedName + '_' + nameCopies
}

export function getUniqueOption(option, listOfOptions) {
  // Get list of values
  const listOfValues = listOfOptions.map(o => o.value)
  // Check if value on list
  let valueCopies = 0
  if (listOfValues.includes(option.value)) {
    // Get an unique value
    const cleanedValue = option.value.replace(/_[0-9]+$/, "")
    valueCopies = 1
    while (listOfValues.includes(cleanedValue + "_" + valueCopies)) {
      valueCopies += 1
    }
    option.value = cleanedValue + "_" + valueCopies
  }

  // Get list of labels
  const listOfLabels = listOfOptions.map(o => o.label)
  // Check if label on list
  if (listOfLabels.includes(option.label)) {
    // Get an unique label
    const cleanedLabel = option.label.replace(/ [0-9]+$/, "")
    if (!listOfLabels.includes(cleanedLabel + " " + valueCopies)) {
      option.label = cleanedLabel + " " + valueCopies
    } else {
      let labelCopies = 1
      while (listOfLabels.includes(cleanedLabel + " " + valueCopies + "." + labelCopies)) {
        labelCopies += 1
      }
      option.label = cleanedLabel + " " + valueCopies + "." + labelCopies
    }
  }

  // Return result
  return option
}

// Rounds and trims number to the digits place
export function roundAndTruncate(number, digits) {
  if (typeof number !== 'number' && typeof digits !== 'number') {
    throw new Error('Parameters must be numbers')
  }
  return parseFloat(number.toFixed(digits))
}

export const baseColor = {
  white: "#fff",
  black: "#213443",
  darkGray: "#c2c2c2",
  blue: "#366f9a",
  blueLight: "#92bcc1",
  green: "#51952e",
  greenLight: "#abcb9c",
  orange: "#d57b3d",
  orangeLight: "#ebbfa2",
  red: "#ba2d2d",
  redLight: "#e09a99",
  teal: "#1b8b8c",
}