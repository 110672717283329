import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationSquare,
  faTimesCircle
} from '@fortawesome/pro-duotone-svg-icons'
import { notification } from 'antd'
import { isDict } from 'libs/utils'
import './index.scss'

// Usage:
// import messenger from 'libs/messenger'
// messenger.showSuccess(message, description, duration)
// or
// messenger.showSuccess({message: 'string', description: 'string', duration: 10})
// or
// messenger.showSuccess(['message 1', 'message 2'])
// or
// messenger.showSuccess([
//  { message: 'message 1', description: 'description 1', duration: 10 },
//  { message: 'message 2', description: 'description 2', duration: 5 }
// ])
// Note 1: Only message is required in all use cases
// Note 2: Methods -> showSuccess, showInfo, showWarning and showError

const className = {
  'success': 'notification-success',
  'info': 'notification-info',
  'warning': 'notification-warning',
  'error': 'notification-error'
}

const icon = {
  'success': <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
  'info': <FontAwesomeIcon icon={faInfoCircle} size="2x" />,
  'warning': <FontAwesomeIcon icon={faExclamationSquare} size="2x" />,
  'error': <FontAwesomeIcon icon={faTimesCircle} size="2x" />
}

const baseMessenger = type => (message, description, duration = 4.5) => {
  const show = (message, description, duration = 4.5) => {
    if (isDict(message)) {
      duration = message.duration || duration
      description = message.description
      message = message.message
    }
    notification[type]({
      className: className[type],
      icon: icon[type],
      message: message,
      description: description,
      duration: duration
    })
  }

  if (message instanceof Array) {
    for (let item in message) {
      show(item)
    }
  } else {
    show(message, description, duration)
  }
}

const messenger = {
  showSuccess: baseMessenger('success'),
  showInfo: baseMessenger('info'),
  showWarning: baseMessenger('warning'),
  showError: baseMessenger('error'),
}

export default messenger