export function navigateTo(
  path,
  keepHash = true,
  keepSearch = false,
  reload = true
) {
  // Check path
  if (!path.startsWith('/')) {
    path = '/' + path
  }
  // Check global function
  if (typeof window.navigateTo === 'function') {
    // Navigate
    window.navigateTo(path, keepHash, keepSearch, reload)
  } else {
    // Navigate
    window.history.pushState(
      null,
      null,
      path +
      (keepHash ? window.location.hash : '') +
      (keepSearch ? window.location.search : '')
    )
    if (reload) {
      // Force reload
      window.location.reload()
    }
  }
}

export function navigateUp(keepHash = true, keepSearch = false, reload = true) {
  // Remove last path from location and navigate to there
  navigateTo(
    '/' +
    window.location.pathname
      .split('/')
      .filter((path) => path !== '')
      .slice(0, -1)
      .join('/'),
    keepHash,
    keepSearch,
    reload
  )
}

export function navigateBack() {
  // Check global function
  if (typeof window.navigateBack === 'function') {
    // Navigate back
    window.navigateBack()
  } else {
    // Navigate back
    window.history.back()
  }
}

export function Link({
  to,
  children,
  keepHash = true,
  keepSearch = false,
  reload = true,
  className = '',
  beforeNavigate = null
}) {
  const onClick = async (event) => {
    // Prevent reloading
    event.preventDefault()
    // Run beforeNavigate
    if (typeof beforeNavigate === 'function') {
      await beforeNavigate(to)
    }
    // Set the new location
    if (to) {
      navigateTo(to, keepHash, keepSearch, reload)
    }
  }

  return (
    <a
      className={'link' + (className ? ' ' + className : '')}
      href={to}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export function LinkBack({ children, className = '', beforeNavigate = null }) {
  const onClick = async (event) => {
    // Prevent reloading
    event.preventDefault()
    // Run beforeNavigate
    if (typeof beforeNavigate === 'function') {
      await beforeNavigate()
    }
    // Set the new location
    navigateBack()
  }

  return (
    <a
      className={'link' + (className ? ' ' + className : '')}
      onClick={onClick}
    >
      {children}
    </a>
  )
}