import React, {useRef, useEffect, useState} from 'react'

import { isColorLight } from 'libs/utils'

// FULL CALENDAR
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons'

import { Popover } from 'antd'

import dayjs from 'dayjs'

import './calendar.scss'
import { faArrowAltRight } from '@fortawesome/pro-duotone-svg-icons'
import { api } from 'libs/api/base'

export default function useCalendar({
  data,
  height = '100%',
  headerHeight = '50.7px',
  uuidFieldName = 'uuid',
  nameFieldName = 'name',
  startDateFieldName = 'start_datetime',
  endDateFieldName = 'end_date',
  popoverFieldNames = [{label: 'Name', value: 'name'}], // automatically adds start and end dates on popover
  colorFieldName = null,
  color = '#366f9a',
  eventClick = () => {},
  setIsLoading = () => {},
  eventId,fullEventData
}) {
  const getDates = (date = new Date()) => {
    // Start Date
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1)
    startDate = new Date(
      startDate.valueOf() - startDate.getDay() * 24 * 60 * 60000
      )
      // End Date
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      endDate = new Date(
        endDate.valueOf() + (7 - endDate.getDay()) * 24 * 60 * 60000
        )
        if ((endDate.valueOf() - startDate.valueOf()) / (7 * 24 * 60 * 60000) < 6) {
          endDate = new Date(endDate.valueOf() + 7 * 24 * 60 * 60000)
        }
        return [startDate, endDate]
      }
 
    const calendarRef = useRef()
    const [view, setView] = useState('month')
    const [title, setTitle] = useState('')
    const [monthQuery, setMonthQuery] = useState('')
    const [events, setEvents] = useState([])
    const [startDate, setStartDate] = useState(getDates()[0])
    const [endDate, setEndDate] = useState(getDates()[1])
    
    const getCalendar = () => {
      if (calendarRef.current) {
        return calendarRef.current.getApi()
      }
      return false
    }
    
    useEffect(() => {
    let calendar = getCalendar()
    if (calendar) {
      setTitle(calendar.view.title)
      calendar.setOption('height', height)
    } else {
      setTitle(dayjs().format('MMMM YYYY'))
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(data)) {
      let updatedArr = [...data] 
      for (var i = 0; i < updatedArr.length; i++) {
        updatedArr[i] = {
          id: updatedArr[i][uuidFieldName],
          title: updatedArr[i][nameFieldName],  //hardcoded
          space_details: updatedArr[i]['space_uuid'] ? {"space_uuid": updatedArr[i]['space_uuid'], "space_type": updatedArr[i]['space_type']} : null,
          color: updatedArr[i][colorFieldName] || color,
          textColor: isColorLight(updatedArr[i][colorFieldName]) ? "#000000" : "#FFFFFF",
          start: new Date(updatedArr[i][startDateFieldName]),
          end: new Date(updatedArr[i][endDateFieldName]),
          display: 'block',
          eventDisplay: 'block',
        }
      }
      setEvents(updatedArr)
    }
  }, [data, monthQuery])

  useEffect(() => {
    setIsLoading(true)
    const timeout = setTimeout(() => {
      setMonthQuery(
        `${startDateFieldName}__gte=${dayjs(startDate).format('YYYY-MM-DD')}&${endDateFieldName}__gte=${dayjs(startDate).format('YYYY-MM-DD')}&${startDateFieldName}__lt=${dayjs(endDate).format('YYYY-MM-DD')}&${endDateFieldName}__lt=${dayjs(endDate).format('YYYY-MM-DD')}`)
      setIsLoading(false)
    }, 700)
    return () => clearTimeout(timeout)
  }, [startDate, endDate])

  const handleButtonClick = btnName => {
    let calendar = getCalendar()
    switch (btnName) {
      case "month":
        calendar.changeView("dayGridMonth")
        setView("month")
        break
      case "week":
        calendar.changeView("timeGridWeek")
        setView("week")
        break
      case "day":
        calendar.changeView("timeGridDay")
        setView("day")
        break
      case "list":
        calendar.changeView("listWeek")
        setView("list")
        break
      case "prev":
        calendar.prev()
        setStartDate(getDates(calendar.view.currentStart)[0])
        setEndDate(getDates(calendar.view.currentStart)[1])
        break
      case "today":
        calendar.today()
        setStartDate(getDates(calendar.view.currentStart)[0])
        setEndDate(getDates(calendar.view.currentStart)[1])
        break
      case "next":
        calendar.next()
        setStartDate(getDates(calendar.view.currentStart)[0])
        setEndDate(getDates(calendar.view.currentStart)[1])
    }
    setTitle(calendar.view.title)
  }

  const popoverContent = (value) => {
      return (
        <div>
          {popoverFieldNames.map((item, key) => {
            return (
              <p key={key}>{item.label}: {value[item.value]}</p>
            )
          })}
          <p>Start: {dayjs(value[startDateFieldName] ? value[startDateFieldName] : null).format('MM-DD-YYYY, h:mma')}</p>
          <p>End: {dayjs(value[endDateFieldName] ? value[endDateFieldName] : null).format('MM-DD-YYYY, h:mma')}</p>
        </div>
      )  
  }

  const getEvent = (value) => {
    let finder = data.find(i => i[uuidFieldName] == value)
    return finder
  }
  
  const renderEventContent = (eventInfo) => {
    let event = getEvent(eventInfo.event.id)
    if (event) {
      return (
        <>
          <Popover content={() => popoverContent(event)}>
            <div className="row px-3">
              <b className="pl-1 pr-3">{dayjs(event[startDateFieldName]).format('h:mma')} - {dayjs(event[endDateFieldName]).format('h:mma')}</b>
              <i>{eventInfo.event.title}</i> 
            </div>
          </Popover>
        </>
      )
    }
  }

  const handleDateClick = date => {
    console.log(date)
    let calendar = getCalendar()
    calendar.changeView("timeGridDay", date.dateStr)
    setView("day")
  }

  const calendar = (
    <div className="h-100">
      <div className="d-flex justify-content-between align-items-center calendar-result-header flex-wrap mb-3">
        <div className="calendar-wrapper">
          {/* <div className="calendar-filters-wrapper">
            <div className="calendar-filter-group-wrapper">
              <div className="form-group calendar-filter-wrapper">
                <label>Locations:</label>
              </div>
              <div className="form-group calendar-filter-wrapper">
                <label>Buildings:</label>
              </div>
            </div>
            <div className="calendar-filter-group-wrapper">
              <div className="form-group calendar-filter-wrapper">
                <label>Seating capacity:</label>
              </div>
              <div className="form-group calendar-filter-wrapper">
                <label>Spaces:</label>
              </div>
            </div>
          </div> */}
          <div
            className="btn-group btn-group-sm calendar-result-buttons"
            role="group"
          >
            <button
              type="button"
              className={
                "btn btn-outline-secondary border-right-0" +
                (view === "month" ? " active" : "")
              }
              onClick={() => handleButtonClick("month")}
            >
              Month
            </button>
            <button
              type="button"
              className={
                "btn btn-outline-secondary border-right-0 border-left-0" +
                (view === "week" ? " active" : "")
              }
              onClick={() => handleButtonClick("week")}
            >
              Week
            </button>
            <button
              type="button"
              className={
                "btn btn-outline-secondary border-right-0 border-left-0" +
                (view === "day" ? " active" : "")
              }
              onClick={() => handleButtonClick("day")}
            >
              Day
            </button>
            <button
              type="button"
              className={
                "btn btn-outline-secondary border-left-0" +
                (view === "list" ? " active" : "")
              }
              onClick={() => handleButtonClick("list")}
            >
              List
            </button>
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="row justify-content-center">
            <h2 className="fc-toolbar-title">{title}</h2>
          </div>
        </div>
        <div
          className="btn-group btn-group-sm calendar-result-buttons"
          role="group"
        >
          <button
            type="button"
            className="btn btn-outline-secondary border-right-0 py-0"
            onClick={() => handleButtonClick("prev")}
          >
            <FontAwesomeIcon
              icon={faCaretLeft}
              style={{ verticalAlign: "middle", fontSize: "1.4rem" }}
            />
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary border-right-0 border-left-0"
            onClick={() => handleButtonClick("today")}
          >
            Today
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary border-left-0 py-0"
            onClick={() => handleButtonClick("next")}
          >
            <FontAwesomeIcon
              icon={faCaretRight}
              style={{ verticalAlign: "middle", fontSize: "1.4rem" }}
            />
          </button>
        </div>
      </div>
      <div className="calendar-view" style={{height: `calc(100% - ${headerHeight})`}}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          headerToolbar={null}
          initialView="dayGridMonth"
          initialDate={fullEventData?.start_date}
          weekends={true}
          events={events}
          dateClick={handleDateClick}
          eventClick={eventClick}
          eventContent={renderEventContent}
          moreLinkClick={e => {
            e.dateStr = dayjs(e.date).format('YYYY-MM-DD')
            handleDateClick(e)
            // return "day"
          }}
          dayMaxEvents={true}
          navLinks={false}
        />
      </div>
    </div>
  )

  const resourceCalendar = (
    <div className="h-100">
      <div className="calendar-view" style={{height: `calc(100% - ${headerHeight})`}}>
        <FullCalendar
          ref={calendarRef}
          schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek'
          }}
          initialDate={fullEventData?.start_date}
          validRange={{
            start:fullEventData?.start_date,
            end:fullEventData?.end_date,
          }}
          initialView="timeGridWeek"
          weekends={true}
          events={events}
          dateClick={handleDateClick}
          eventClick={eventClick}
          eventContent={renderEventContent}
          moreLinkClick={e => {
            e.dateStr = dayjs(e.date).format('YYYY-MM-DD')
            handleDateClick(e)
            // return "day"
          }}
          dayMaxEvents={true}
          navLinks={false}
        />
      </div>
    </div>
  )

  return [
    calendar,
    monthQuery,
    resourceCalendar,
  ]
}


/*
name 
start/end date
status
primary coordinator
*/