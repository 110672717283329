import './index.scss'
import React from 'react'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { ButtonDanger } from 'components/buttons'
import { ContentSpinner } from 'components/spinner'
import Header from './Header'
import Sidebar, { UserProfile } from './Sidebar'
import Footer from './Footer'


export default function AppLayout(props) {
  const [collapsed, setCollapsed] = React.useState(window.innerWidth <= 1024)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const menuIcon = React.createElement(
    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
    {
      className: 'menu-icon',
      onClick: toggleCollapsed
    }
  )
  
  if (props.inIframe) {
    return (
      <ContentSpinner className="app-layout-spinner app-iFrame" spinning={props.isLoading}>
        {props.children}
      </ContentSpinner>
    )
  }


  if (props.links?.length) {
    return (
      <>
        <Header menuIcon={menuIcon} company={props.company} />
        <div className="app-layout-outer">
          <Sidebar
            account={props.account}
            links={props.links}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <div className="app-layout-inner">
            <ContentSpinner className="app-layout-spinner" spinning={props.isLoading}>
              {props.children}
            </ContentSpinner>
            <Footer />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Header company={props.company}
        notifications={props.account ? <>
          <span>
            <UserProfile account={props.account} />
          </span>
          <ButtonDanger title="Logout" text="Logout" onClick={window.eventops.logout} />
        </> : null}
      />
      <div className="app-layout-outer">
        <div className="app-layout-inner no-sidebar">
          <ContentSpinner className="app-layout-spinner" spinning={props.isLoading}>
            {props.children}
          </ContentSpinner>
          <Footer />
        </div>
      </div>
    </>
  )
}