import React from 'react'

export default function SelectWidget(props) {

  return (
      <>
        <div className="mx-auto">
          <h2 className="text-center my-5 p-3">Select a widget</h2>
        </div>
      </>
  )
}
