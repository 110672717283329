import React from 'react'
import BaseField from './BaseField'


export default function FieldIframe(props) {
  /* iFrame
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * url = <string> Required
   * query = <array> Optional
   * height = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * fieldsData = <object> Required if query
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */
  const [url, setUrl] = React.useState()

  // INITIALIZATION
  const makeUrl = () => {
    if (props.url) {
      let query = ""
      if (props.query?.length > 0) {
        let fieldData
        for (let param of props.query) {
          fieldData = props.fieldsData[param.field]
          if (fieldData) {
            query += (query ? "&" : "?") + encodeURIComponent(param.param) + "=" + encodeURIComponent(fieldData.value.replace(/[|]{2}/g, "\n"))
          }
        }
      }
      if (query) {
        if (props.url.includes("?")) {
          setUrl(props.url.replace("?", query + "&"))
        } else {
          setUrl(props.url + query)
        }
      } else {
        setUrl(props.url)
      }
    } else if (url !== undefined) {
      setUrl()
    }
  }
  React.useEffect(() => {
    if (!url || props.url) {
      const delayedChange = setTimeout(makeUrl, 500)
      return () => clearTimeout(delayedChange)
    } else {
      makeUrl()
    }
  }, [props.url])

  // RENDER
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={url && (
        <iframe
          className="custom-field-input"
          src={url}
          width="100%"
          style={{ height: props.height ? props.height + "px" : "500px" }}
        />
      )}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
