import React from 'react'
import { Modal } from 'antd'
import { ButtonCancel, ButtonSave, ButtonOk } from 'components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/pro-duotone-svg-icons"
import './index.scss'


export default function Dialog({
  visible = false, // boolean
  className, // string
  title, // string
  buttonClose, // boolean or ReactNode
  buttonCancel, // boolean or ReactNode
  onCancel, // function
  buttonOk, // boolean or ReactNode
  onOk, // function
  buttonOkStyle = "save", // string -> ["ok", "save"]
  children, // ReactNode
  isLoading, // Will disable ButtonSave
  centered, // boolean
  innerContainer = false,
  keyboard = true,
}) {
  const closeIcon = React.useMemo(() => (
    React.isValidElement(buttonClose) ? buttonClose :
      buttonClose && typeof onCancel === 'function' && <FontAwesomeIcon icon={faTimes} className="dialog-close" title="Close" />
  ), [buttonClose, onCancel])

  const footer = React.useMemo(() => (
    <div className='dialog-footer'>
      {React.isValidElement(buttonCancel) ? buttonCancel :
        buttonCancel && typeof onCancel === 'function' && <ButtonCancel onClick={onCancel} />}
      {React.isValidElement(buttonOk) ? buttonOk :
        buttonOk && typeof onOk === 'function' && (buttonOkStyle === "save" ?
          <ButtonSave onClick={onOk} disable={isLoading} /> : <ButtonOk onClick={onOk} disable={isLoading} />)
      }
    </div>
  ), [buttonCancel, onCancel, buttonOk, onOk, isLoading])

  return (
    <Modal
      className={className}
      visible={visible}
      wrapClassName="dialog"
      maskClosable={false}
      getContainer={innerContainer ? () => document.getElementsByClassName("app-layout-inner")[0] :
        () => document.getElementById("modal-root")
      }
      modalRender={(modal) => <div onMouseDown={e => e.stopPropagation()} children={modal} />}
      // Header
      title={<div className="dialog-title">{title}</div>}
      closable={closeIcon && true}
      closeIcon={closeIcon}
      // Footer
      onCancel={onCancel}
      onOk={onOk}
      footer={footer}
      centered={centered}
      keyboard={keyboard}
    >{children}</Modal>
  )
}
