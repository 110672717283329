import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { isValidEmail } from 'libs/utils'
import iconLogo from 'assets/images/eventopsIconLogoWhite.png'
import api from 'libs/api'
import './index.scss'


function Login({ setForgotPassword, isLoading, setIsLoading, email, setEmail }) {
  const [password, setPassword] = React.useState("")
  const [rememberMe, setRememberMe] = React.useState(true)
  const [ignoreEmail, setIgnoreEmail] = React.useState(true)
  const [ignorePassword, setIgnorePassword] = React.useState(true)

  const loginHandler = event => {
    event.preventDefault()
    if (isLoading) {
      // Do nothing
    } else if (!window.eventops) {
      // Something went wrong
      window.location.reload()
    } else if (email && password && isValidEmail(email)) {
      // Try to login
      setIsLoading(true)
      window.eventops.login(email, password, rememberMe)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false))
    } else {
      // Show errors
      setIgnoreEmail(false)
      setIgnorePassword(false)
    }
  }

  return (
    <form className="login-form" onSubmit={loginHandler}>
      <p>Please provide your email address and continue to login</p>

      <div className="login-input">
        <input
          type="text"
          placeholder="Email"
          className={"form-control" + (ignoreEmail || isValidEmail(email) ? "" : " is-invalid")}
          value={email}
          onChange={e => setEmail(e.target.value)}
          onBlur={() => { if (ignoreEmail) { setIgnoreEmail(false) } }}
          required
        />
        <div className="invalid-feedback">
          {email ? "Please enter a valid email address" : "Email is required"}
        </div>
      </div>

      <div className="login-input">
        <input
          type="password"
          placeholder="Password"
          className={"form-control" + (ignorePassword || password !== "" ? "" : " is-invalid")}
          value={password}
          onChange={e => setPassword(e.target.value)}
          onBlur={() => { if (ignorePassword) { setIgnorePassword(false) } }}
          required
        />
        <div className="invalid-feedback">
          Password is required
        </div>
      </div>

      <div className="row forgot-password-area">
        <div className="col-6">
          <input
            id="rem"
            type="checkbox"
            className="float-left"
            checked={rememberMe}
            onChange={e => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rem" className="float-left">Remember me</label>
        </div>
        <div className="col-6 text-right">
          <a
            className={isLoading ? "disabled" : ""}
            onClick={() => !isLoading && setForgotPassword(true)}
          >
            Forgot your password?
          </a>
        </div>
      </div>

      <span className={"login-button-arrow" + (isLoading ? " disabled" : "")} onClick={loginHandler}>
        {isLoading ?
          <FontAwesomeIcon icon={faSpinnerThird} spin />
          :
          <FontAwesomeIcon icon={faChevronRight} />
        }
      </span>

      <button className="login-button" title="Login" onClick={loginHandler} disabled={isLoading}>
        Login
      </button>

    </form>
  )
}


function ForgotPassword({ setForgotPassword, isLoading, setIsLoading, email, setEmail }) {
  const [ignoreEmail, setIgnoreEmail] = React.useState(true)

  const submitHandler = event => {
    event.preventDefault()
    if (isLoading) {
      // Do nothing
    } else if (email && isValidEmail(email)) {
      // Try to request a link
      setIsLoading(true)
      api.post('auth/forgot-password', { email: email })
        .then(([valid, response]) => {
          if (valid) {
            setForgotPassword(false)
          }
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    } else {
      // Show errors
      setIgnoreEmail(false)
    }
  }

  return (
    <form className="login-form" onSubmit={submitHandler}>
      <p>Please provide your email address</p>

      <div className="login-input">
        <input
          type="text"
          placeholder="Email"
          className={"form-control" + (ignoreEmail || isValidEmail(email) ? "" : " is-invalid")}
          value={email}
          onChange={e => setEmail(e.target.value)}
          onBlur={() => { if (ignoreEmail) { setIgnoreEmail(false) } }}
          required
        />
        <div className="invalid-feedback">
          {email ? "Please enter a valid email address" : "Email is required"}
        </div>
      </div>

      <div className="row forgot-password-area">
        <div className="col-6">
          <a
            className={isLoading ? "disabled" : ""}
            onClick={() => !isLoading && setForgotPassword(false)}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Back to Login
          </a>
        </div>
        <div className="col-6">
          <span
            className={"login-button-arrow forgot-password" + (isLoading ? " disabled" : "")}
            onClick={submitHandler}
          >
            {isLoading ?
              <FontAwesomeIcon icon={faSpinnerThird} spin />
              :
              <FontAwesomeIcon icon={faChevronRight} />
            }
          </span>
          <button
            className="login-button forgot-password"
            title="Submit"
            onClick={submitHandler}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </div>

    </form>
  )
}


function CreatePassword({ cancelCreatePassword, isLoading, setIsLoading, email }) {
  const [password1, setPassword1] = React.useState("")
  const [password2, setPassword2] = React.useState("")
  const [ignorePassword1, setIgnorePassword1] = React.useState(true)
  const [ignorePassword2, setIgnorePassword2] = React.useState(true)

  const submitHandler = event => {
    event.preventDefault()
    if (isLoading) {
      // Do nothing
    } else if (!window.eventops) {
      // Something went wrong
      window.location.reload()
    } else if (password1 && password2 && password1 === password2) {
      // Try to create a password
      setIsLoading(true)
      api.post('auth' + window.location.pathname, { password: password1 })
        .then(([valid, response]) => {
          if (valid) {
            cancelCreatePassword()
          }
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    } else {
      // Show errors
      setIgnorePassword1(false)
      setIgnorePassword2(false)
    }
  }

  return (
    <form className="login-form" onSubmit={submitHandler}>
      <p>Please use the form below to create a new password for you</p>

      <div className="login-input">
        <input
          type="text"
          placeholder="Email"
          className={"form-control"}
          value={email}
          readOnly={true}
          disabled={true}
        />
      </div>

      <div className="login-input">
        <input
          type="password"
          placeholder="New password"
          className={"form-control" + (ignorePassword1 || password1 !== "" ? "" : " is-invalid")}
          value={password1}
          onChange={e => setPassword1(e.target.value)}
          onBlur={() => { if (ignorePassword1) { setIgnorePassword1(false) } }}
          required
        />
        <div className="invalid-feedback">
          {email ? "Please enter a valid email address" : "Email is required"}
        </div>
      </div>

      <div className="login-input">
        <input
          type="password"
          placeholder="Confirm your new password"
          className={
            "form-control"
            + (ignorePassword2 || (password2 !== "" && password1 === password2) ? "" : " is-invalid")
          }
          value={password2}
          onChange={e => setPassword2(e.target.value)}
          onBlur={() => { if (ignorePassword2) { setIgnorePassword2(false) } }}
          required
        />
        <div className="invalid-feedback">
          {password2 ? "Passwords do not match" : "Please confirm your new password"}
        </div>
      </div>

      <div className="row forgot-password-area">
        <div className="col-6">
          <a
            className={isLoading ? "disabled" : ""}
            onClick={() => !isLoading && cancelCreatePassword()}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Back to Login
          </a>
        </div>
        <div className="col-6">
          <span
            className={"login-button-arrow forgot-password" + (isLoading ? " disabled" : "")}
            onClick={submitHandler}
          >
            {isLoading ?
              <FontAwesomeIcon icon={faSpinnerThird} spin />
              :
              <FontAwesomeIcon icon={faChevronRight} />
            }
          </span>
          <button
            className="login-button forgot-password"
            title="Submit"
            onClick={submitHandler}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </div>

    </form>
  )
}


export default function Auth(props) {
  const createPassword = React.useMemo(() => props.account ? true : false)
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [email, setEmail] = React.useState(props.account?.user?.email || "")

  React.useEffect(() => {
    if (props.cancelCreatePassword) {
      window.navigateTo('/', false, false, false)
    }
  }, [])

  const cancelCreatePassword = () => {
    props.clearAccount()
    window.navigateTo('/', false, false, false)
  }

  const childProps = {
    setForgotPassword: setForgotPassword,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    email: email,
    setEmail: setEmail
  }

  return (
    <div className="login-page">

      <div className="login-content">

        <div className="login-logo">
          <img src={iconLogo} />
        </div>

        {createPassword ?
          <CreatePassword cancelCreatePassword={cancelCreatePassword} {...childProps} />
          :
          forgotPassword ?
            <ForgotPassword {...childProps} />
            :
            <Login {...childProps} />
        }

      </div>

      <div className="login-footer">
        &copy; {new Date().getFullYear()} Elevar Technologies, Inc.
      </div>

    </div >
  )
}
