import { useContext, useMemo } from 'react'
import moment from 'moment-timezone'
import TimePicker from 'antd/es/time-picker'
import { momentLocalTimeFormat, range } from 'libs/utils'
import { SettingsContext } from 'App'
import BaseField from './BaseField'


export default function FieldTime(props) {
  /* Input Time
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * min = <integer> Optional
   * max = <integer> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   * minuteStep = <int> Optional (Default = 5)
   * format = <string> Optional
   */
  const ignoreOffset = props.type === "time"
  const toString = value => value?.toISOString(ignoreOffset).substr(11, 5) || null
  const fromString = value => !value ? "" : props.timezone ?
    moment.tz(ignoreOffset ? value : `${value}Z`, "HH:mmZ", props.timezone)
    :
    moment(ignoreOffset ? value : `${value}Z`, "HH:mmZ")

  // Memos
  const time = useMemo(() => fromString(props.value), [props.value, props.timezone])

  const minTime = useMemo(() => fromString(props.min), [props.min, props.timezone])

  const maxTime = useMemo(() => fromString(props.max), [props.max, props.timezone])

  const timezoneAbbr = useMemo(() => {
    if (time && props.timezone && time.utcOffset() !== moment().utcOffset()) {
      return isNaN(time.zoneAbbr()) ? " z" : ` [GMT${time.utcOffset() >= 0 ? "+" : ""}${(time.utcOffset() / 60).toFixed(2)}]`
    }
    return ""
  }, [time, props.timezone])

  const companySettings = useContext(SettingsContext)
  const defaultTime = useMemo(() => {
    if (time) return time
    const value = `${props.name?.startsWith("start") ? companySettings.default_start_hour : companySettings.default_end_hour}:00`
    return props.timezone ? moment.tz(value, "H:00", props.timezone) : moment(value, "H:mm")
  }, [time, props.timezone])

  // Functions
  const handleChange = (nextTime) => {
    if (props.timezone) {
      nextTime?.tz(props.timezone, true)
    }
    props.onChange({ target: { value: toString(nextTime) } })
  }

  // - Check min/max time
  const getHourValue = (date) => date ? Number(date.toISOString(true).slice(11, 13)) : null
  const getMinuteValue = (date) => date ? Number(date.toISOString(true).slice(14, 16)) : null
  const disabledHours = () => {
    const minHour = minTime ? getHourValue(minTime) : 0
    const maxHour = maxTime ? getHourValue(maxTime) : 23
    return range(0, 23).filter(h => h < minHour || h > maxHour)
  }
  const disabledMinutes = (hour) => {
    if (hour < 0) return range(0, 59)
    const minMinute = minTime && hour === getHourValue(minTime) ? getMinuteValue(minTime) : 0
    const maxMinute = maxTime && hour === getHourValue(maxTime) ? getMinuteValue(maxTime) : 59
    return range(0, 59).filter(m => m < minMinute || m > maxMinute)
  }

    //fn called when a date is selected
    const updateDateWhenClicked =(e)=>{
      props.onChange({ target: { value: toString(e) } })
    }
  
  

  // Render
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        <TimePicker
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          defaultValue={defaultTime}
          format={props.format || `${momentLocalTimeFormat}${timezoneAbbr}`}
          minuteStep={props.minuteStep || 5}
          placeholder={props.placeholder}
          value={time}
          onChange={handleChange}
          onBlur={props.onBlur}
          disabled={props.disabled || props.readOnly}
          inputReadOnly={props.readOnly}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          onSelect={updateDateWhenClicked}

          
        />
      }
      prepend={props.prepend}
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
