import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import SessionCalendarView from './SessionCalendarView'
import RegistrationForm from './RegistrationForm'
import SelectWidget from './SelectWidget'
import { api } from 'libs/api/base'

export default function Widgets(props) {

  const [fullEventData, setFullEventData] = useState(null)

  const { eventId, widgetName } = useParams()

  //calling event api to get event start/end dates
  const getEventData =async ()=>{
    props.setIsLoading(true)
    let read = await api
    .get(`api/event/${eventId}`)
    .then(([success, response]) => {
      if (success) {
        setFullEventData(response.data)
        props.setIsLoading(false)
        return response.data
    }
  })
  .catch(err => {
    props.setIsLoading(false)
    console.log(err)
  })
  return read
}

  useEffect(() => {
    getEventData()
  }, [])

  const widget = 
    widgetName === 'registration-form' ? <RegistrationForm eventId={eventId} /> :
    widgetName === 'session-calendar-view' ?fullEventData&& <SessionCalendarView eventId={eventId} props={props} fullEventData={fullEventData} /> : <SelectWidget />

  return widget
}
