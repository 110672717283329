import React from 'react'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTachometerAlt,
  faCalendarAlt,
  faTasks,
  faFile,
  faStream,
  faBuilding,
  faAnalytics,
  faUserCog,
  faCogs,
  faSignOutAlt,
  faChevronCircleRight,
  faChevronRight,
  faChevronDown
} from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'libs/utils'
import logoWhite from 'assets/images/eventopsLogoWhite.png'
import dummyAvatar from 'assets/images/dummyAvatar.png'
import './Sidebar.scss'


// Profile
export const UserProfile = ({ account }) => {

  return (
    <div className="user-profile">
      <img
        className="user-image"
        src={account.profile_image || dummyAvatar}
        alt={account.name ? account.name[0].toUpperCase() : ''}
      />
      <div className="user-info">
        <div>{account.name || account.email}</div>
        <div>
          <small>{account.role}</small>
        </div>
      </div>
    </div>
  )
}


// Menu
const icons = {
  generic: <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />,
  dashboard: <FontAwesomeIcon icon={faTachometerAlt} size="lg" />,
  events: <FontAwesomeIcon icon={faCalendarAlt} size="lg" />,
  tasks: <FontAwesomeIcon icon={faTasks} size="lg" />,
  files: <FontAwesomeIcon icon={faFile} size="lg" />,
  forms: <FontAwesomeIcon icon={faStream} size="lg" />,
  resources: <FontAwesomeIcon icon={faBuilding} size="lg" />,
  reports: <FontAwesomeIcon icon={faAnalytics} size="lg" />,
  user: <FontAwesomeIcon icon={faUserCog} size="lg" />,
  company: <FontAwesomeIcon icon={faCogs} size="lg" />,
  logout: <FontAwesomeIcon icon={faSignOutAlt} size="lg" />,
  collapse: <FontAwesomeIcon icon={faChevronDown} />,
  uncollapse: <FontAwesomeIcon icon={faChevronRight} />,
  subMenu: <FontAwesomeIcon icon={faChevronCircleRight} size="lg" />,
}

const MenuSubItem = ({ link, currentPath }) => {
  const isActive = link.to && link.to === currentPath
  const className = "menu-sub-item" + (isActive ? " menu-sub-item-active" : "")

  const icon = (
    <span className="menu-sub-item-icon">
      {icons.subMenu}
    </span>
  )

  const label = (
    <span className="menu-sub-item-label">
      {link.label}
    </span>
  )

  return (
    <li className={className}>
      <Link to={link.to}>
        {icon}{label}
      </Link>
    </li>
  )
}

// TODO: NEED TO TEST SUB-PARENT... NO LINK WITH A GRANDCHILD YET
const MenuSubItemParent = ({ link, currentPath }) => {
  const [subCollapsed, setSubCollapsed] = React.useState(!link.links.some(subLink => subLink.to && subLink.to === currentPath))
  const isActive = link.to && link.to === currentPath
  const className = "menu-sub-item" + (isActive ? " menu-sub-item-active" : "")
  const subClassName = subCollapsed ? "menu-sub-items-collapsed" : "menu-sub-items"

  const toggleCollapsed = event => {
    event?.stopPropagation()
    setSubCollapsed(collapsed => !collapsed)
  }

  const icon = (
    <span className="menu-sub-item-icon">
      {icons.subMenu}
    </span>
  )

  const label = (
    <span className="menu-sub-item-label">
      {link.label}
    </span>
  )

  const collapseIcon = (
    <span className="menu-item-collapse-icon" onClick={toggleCollapsed}>
      {subCollapsed ? icons.uncollapse : icons.collapse}
    </span>
  )

  return (
    <>
      <li className={className}>
        <Link className="link-parent" to={link.to}>
          {icon}{label}
        </Link>
        {collapseIcon}
      </li>
      <ul className={subClassName}>
        {link.links.map((link, key) => (
          <MenuSubItem key={link.to || key} link={link} currentPath={currentPath} />
        ))}
      </ul>
    </>
  )
}

const MenuItem = ({ link, currentPath }) => {
  const isActive = link.to && link.to === currentPath
  const className = "menu-item" + (isActive ? " menu-item-active" : "")

  const icon = (
    <span className="menu-item-icon">
      {link.icon ? icons[link.icon] : icons.generic}
    </span>
  )

  const label = (
    <span className="menu-item-label">
      {link.label}
    </span>
  )

  return (
    <li className={className}>
      <Link to={link.to} beforeNavigate={link.beforeNavigate}>
        {icon}{label}
      </Link>
    </li>
  )
}

const MenuItemParent = ({ link, currentPath, collapsed }) => {
  const hasActiveChildren = () => link.links.some(subLink =>
    (subLink.to && subLink.to === currentPath) ||
    (subLink.links instanceof Array && subLink.links.some(subSubLink => subSubLink.to && subSubLink.to === currentPath))
  )
  const [subCollapsed, setSubCollapsed] = React.useState(!hasActiveChildren())
  const isActive = (link.to && link.to === currentPath) || (collapsed && hasActiveChildren())
  const className = "menu-item" + (isActive ? " menu-item-active" : "")
  const subClassName = subCollapsed ? "menu-sub-items-collapsed" : "menu-sub-items"

  const toggleCollapsed = event => {
    event?.stopPropagation()
    setSubCollapsed(collapsed => !collapsed)
  }

  const icon = (
    <span className="menu-item-icon">
      {link.icon ? icons[link.icon] : icons.generic}
    </span>
  )

  const label = (
    <span className="menu-item-label">
      {link.label}
    </span>
  )

  const collapseIcon = (
    <span className="menu-item-collapse-icon" onClick={toggleCollapsed}>
      {subCollapsed ? icons.uncollapse : icons.collapse}
    </span>
  )

  return (
    <>
      <li className={className}>
        <Link className="link-parent" to={link.to} beforeNavigate={!link.to && toggleCollapsed}>
          {icon}{label}
        </Link>
        {collapseIcon}
      </li>
      <ul className={subClassName}>
        {link.links.map((link, key) => (
          !link.links || !(link.links instanceof Array) ?
            <MenuSubItem key={link.to || key} link={link} currentPath={currentPath} />
            :
            <MenuSubItemParent key={link.to || key} link={link} currentPath={currentPath} />
        ))}
      </ul>
    </>
  )
}

const Menu = ({ links, collapsed }) => {
  const currentPath = useLocation().pathname + (useLocation().pathname.endsWith("/") ? "" : "/")

  const logout = async () => {
    await window.eventops?.logout(currentPath === "/")
  }

  return (
    <ul className="sidebar-menu">

      {links instanceof Array && links.map((link, key) => (
        !link.links || !(link.links instanceof Array) ?
          <MenuItem key={link.to || key} link={link} currentPath={currentPath} />
          :
          <MenuItemParent
            key={link.to || key}
            link={link}
            currentPath={currentPath}
            collapsed={collapsed}
          />
      ))}

      <MenuItem link={{
        icon: "logout",
        label: "Logout",
        to: "/",
        beforeNavigate: logout
      }} />

    </ul>
  )
}


// Logo
const Logo = () => {

  return (
    <div className="sidebar-logo">
      <a href="https://www.eventopssoftware.com/" target="new">
        <img src={logoWhite} alt="EventOPS" />
      </a>
    </div>
  )
}


// Main Sidebar
const Sidebar = ({ collapsed, setCollapsed, account, links }) => {
  const [hover, setHover] = React.useState(false)

  React.useEffect(() => {
    // Add resize listener on mount
    window.onresize = event => {
      if (event.target.innerWidth <= 1024) {
        setCollapsed(true)
      }
    }
    // Remove resize listener on unmount
    return () => {
      window.onresize = null
    }
  }, [])

  const onMouseEnter = () => {
    if (window.innerWidth > 480) {
      setHover(true)
    }
  }

  const onMouseLeave = () => {
    setHover(false)
  }

  return account && (
    <aside
      className={(collapsed && !hover) ? 'app-layout-sidebar-collapsed' : 'app-layout-sidebar'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <UserProfile account={account} />
      <Menu links={links} collapsed={collapsed && !hover} />
      <Logo />
    </aside>
  ) 
}

Sidebar.defaultProps = {
  account: {},
  links: [],
  collapsed: false,
  setCollapsed: function () { }
}

export default Sidebar
