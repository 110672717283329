import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from 'apps/notfound'


export default function Participants(props) {

  return (
    <Switch>
      <Route exact path="/">
        <Intro />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

const Intro = () => {

  return (
    <h1>WELCOME TO PARTICIPANTS!!!</h1>
  )
}
