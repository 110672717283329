import React from 'react'
import BaseField from './BaseField'
import { copyDeep, isEqual, sortArrayByKey } from 'libs/utils'


export default function FieldDropdown(props) {
  /* Input Dropdown
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * values = [{ label: <string>, value: <string> }] Required
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * showEmpty = <boolean> Optional (default true)
   * emptyValue = <any> Optional (default "")
   * ignoreBadValue = <boolean> Optional (default false)
   * reorder = <boolean> Optional (default true)
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */
  const [options, setOptions] = React.useState(null)
  const [values, setValues] = React.useState(null)
  const [prevValues, setPrevValues] = React.useState()
  const emptyValue = "emptyValue" in props ? props.emptyValue : ""

  // INITIALIZATION
  React.useEffect(() => {
    if (props.readOnly && [emptyValue, undefined].includes(props.value)) {
      setOptions([])
    } else if (!isEqual(prevValues, props.values)) {
      // Get options
      const newOptions = props.values?.length > 0 ?
        (props.showEmpty === false ? [] : [{ label: "---- Select an option ----", value: emptyValue, isEmpty: true }]).concat(
          props.reorder === false ? props.values : sortArrayByKey(props.values, "label")
        )
        :
        [{ label: "No Data", value: emptyValue, isEmpty: true }]
      // Save options
      setOptions(newOptions.map((option, key) =>
        <option key={key} value={option.isEmpty ? emptyValue : "" + option.value}>{"" + option.label}</option>
      ))
      // Get values
      const newValues = newOptions.map(option => option.isEmpty ? emptyValue : "" + option.value)

      // Save values
      setValues(copyDeep(newValues))
      // Check value
      if (props.value !== undefined && !newValues.includes(props.value)) {
        props.onChange({ target: { value: (props.showEmpty === false && newValues[0]) || "" }})
      }
      setPrevValues(copyDeep(props.values))
    }
  }, [props.value, props.values, props.readOnly])

  // VALIDATION
  // Check if state value is a valid option in values
  React.useEffect(() => {
    if (!props.ignoreBadValue && values !== null && props.value !== undefined && props.value !== emptyValue && !values.includes(props.value)) {
      props.onChange({ target: { value: (props.showEmpty === false && values[0]) || emptyValue } })
    }
  }, [values, props.value])

  // RENDER
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        <select
          ref={props.inputRef}
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          name={props.name}
          type="dropdown"
          value={props.value || ""}
          required={props.required && props.valid === false}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled || props.readOnly}
          readOnly={props.readOnly}
          autoFocus={props.autoFocus}
        >
          {options}
        </select>
      }
      prepend={props.prepend}
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
