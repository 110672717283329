import React from 'react'
import PropTypes from 'prop-types'
import Button from 'antd/es/button'
import classNames from 'classnames'

/*
 * Button BaseButtonIconText Expected Props:
 * Block <boolean> Optional - Option to fit button width to its parent width
 * className = <string> Optional - class of button
 * type = <string> Optional - Can be set to primary ghost dashed link text default
 * htmlType = <string> Optional - Set the original html type of button
 * title = <string> Optional - hover text
 * disabled = <boolean> Optional - Disabled state of button
 * icon = <ReactNode> Optional - Set the icon component of button
 * shape = <string: circle | round> Optional - Can be set button shape
 * size = <string: large | middle | small> Optional - Set the size of button
 * loading = <boolean | { delay: number }> Optional - 	Set the loading status of button
 * onClick = <Function()> Optional - 	Set the handler to handle click event
 * text = <string> Required
 */

/*
 * Example icon for font-awesome:
 * icon={<i className="fa fa-calendar mr-2"></i>}
 */

const BaseButtonIconText = ({
  block = false,
  border = false,
  className = '',
  disabled = false,
  effectColor,
  ghost,
  href,
  htmlType = 'button',
  icon,
  loading,
  onClick,
  shape,
  size = 'middle',
  target,
  text,
  title = '',
  type = 'primary',
}) => {

  return (
    <Button
      className={classNames(className, effectColor && effectColor + '-button', border ? null : 'border-0')}
      type={type}
      htmlType={htmlType}
      block={block}
      href={href}
      ghost={ghost}
      target={target}
      title={title}
      disabled={disabled}
      shape={shape}
      size={size}
      loading={loading}
      onClick={onClick}
      >
      {text}{icon}
    </Button>
  )
}

BaseButtonIconText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'danger',
    'ghost',
    'dashed',
    'link',
    'text',
    'default'
  ]),
  htmlType: PropTypes.string,
  block:PropTypes.bool,
  href:PropTypes.string,
  target:PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  shape: PropTypes.oneOf([
    'circle',
    'round'
  ]),
  size: PropTypes.oneOf([
    'large',
    'middle',
    'small'
  ]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.node,
  effectColor: PropTypes.oneOf([
    'red',
    'green',
    'blue',
    'orange',
    'teal',
    'purple',
  ]),
}

export default BaseButtonIconText
