import React from 'react'
import ReactMarkdown from 'react-markdown'


export default function Markdown(props) {
  /* Markdown
 * Expected Props: * 
 * input = <string> Required
 */
  const [markdown, setMarkdown] = React.useState(null)

  // INITIALIZATION
  React.useEffect(() => {
    const readInput = () => {
      if (!props.input) {
        return null
      }
      const tagOptions = /<left>|<center>|<right>/gi
      const tagMap = {
        "<left>": "text-left",
        "<center>": "text-center",
        "<right>": "text-right",
      }
      const tagList = props.input.match(tagOptions)
      const inputList = props.input
        .replace(/<br>\s*/gi, "<br>\n\n")
        .split(tagOptions)

      const imageRender = props => {
        return <img {...props} style={{ maxWidth: "100%" }} />
      }

      const linkRender = props => {
        return <a {...props} rel="noopener noreferrer" />
      }

      const htmlRender = props => {
        return props.value === "<br>" ? <br /> : props.value
      }

      return (
        <div
          className={"markdown" + (props.className ? " " + props.className : "")}
          style={props.style}
        >
          {inputList.map((text, key) => text !== "" ? (
            <ReactMarkdown
              key={key}
              className={key > 0 ? tagMap[tagList[key - 1]] : ""}
              source={text}
              linkTarget="_blank"
              renderers={{
                image: imageRender,
                html: htmlRender,
                link: linkRender,
              }}
            />
          ) : null)}
        </div>
      )
    }
    setMarkdown(readInput())
  }, [props.input])

  return markdown
}
