import React from 'react'
import classNames from 'classnames'


export default function BaseField({
  className,
  labelClassName,
  inputClassName,
  label,
  prepend,
  input,
  append,
  disabled,
  readOnly,
  required,
  valid,
  validMessage,
  invalidMessage,
  help,
  helpAboveInput,
  horizontal,
  horizontalLabelSize = 6,
}) {
  /* Base Input
   * Expected Props:
   * className = <string> Optional
   * labelClassName = <string> Optional
   * inputClassName = <string> Optional
   * label = <string> Required
   * prepend = <string> Optional
   * input = <JSX content>
   * append = <string> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * required = <boolean> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */

  if (horizontalLabelSize < 1) {
    horizontalLabelSize = 1
  } else if (horizontalLabelSize > 23) {
    horizontalLabelSize = 23
  }

  const labelNode = React.useMemo(() => label && (horizontal ?
    <label className={classNames(labelClassName, "custom-field-label")}>
      <span>
        {label.endsWith(":") ? label : label + ":" /* TODO: Keep that forced ":"? */}
        {required && <span className="text-danger">* </span>}
      </span>
    </label>
    :
    <label className={classNames(labelClassName, "custom-field-label")}>
      {label.endsWith(":") ? label : label + ":" /* TODO: Keep that forced ":"? */}
      {required && <span className="text-danger"> *</span>}
    </label>
  ), [label, horizontal, required])

  const inputNode = (
    <>
      {help && helpAboveInput &&
        <small className="form-text text-muted mt-0 mb-2 custom-field-help">{help}</small>
      }

      {prepend || append ? (
        <div className="input-group">
          {prepend && (
            <div className="input-group-prepend">
              <span className="input-group-text">{prepend}</span>
            </div>
          )}
          {input}
          {append && (
            <div className="input-group-append">
              <span className="input-group-text">{append}</span>
            </div>
          )}
        </div>
      ) : input}

      {validMessage && valid === true && (
        <div className="valid-feedback custom-field-valid">
          {validMessage}
        </div>
      )}

      {invalidMessage && valid === false && (
        <div className="invalid-feedback custom-field-invalid">
          {invalidMessage}
        </div>
      )}

      {help && !helpAboveInput &&
        <small className="form-text text-muted custom-field-help">{help}</small>
      }
    </>
  )

  return (
    <div className={classNames("form-group", className, {
      "form-group-horizontal": horizontal,
      "form-group-disabled": disabled,
      "form-group-readonly": readOnly
    })}>
      {
        horizontal ? (
          <>
            <div className={`ant-col-${horizontalLabelSize} form-label`}>{labelNode}</div>
            <div className={classNames(`ant-col-${24 - horizontalLabelSize} form-input`, inputClassName)}>{inputNode}</div>
          </>
        ) : (<>{labelNode}{inputClassName ? <div className={inputClassName}>{inputNode}</div> : inputNode}</>)
      }
    </div>
  )
}
