import Content from "components/layout/Content";
import './Invitation.scss'

export default function InvitationAccepted(props)
{
  const title = "Invitation Accepted"
  return(<Content title={title} maxWidth>
   <main className="app-layout-content custom-main content-centered-invitation">
     <h3>Thank you for your response!</h3>
     <div className="mt-2">An email with registration information will be sent soon.</div>
    </main>
  </Content>)
}