// STYLES
import "./widget.scss"
// SCRIPTS
import React from "react"
//import { getBuildings, getFeatures, getSpaces, lockSpace, unlockSpace } from "../../eventops/eventops"
// COMPONENTS
import { ButtonAdd, ButtonCancel } from "components/buttons"
// Ant Design
import Select from "antd/es/select"
import Input from "antd/es/input"
import Table from "antd/es/table"
// Prime React
import { ProgressSpinner } from 'primereact/progressspinner'
import api from 'libs/api'

const getBuildings = () => ({})
const getFeatures = () => ({})

const getSpaces = async(data) => {
  let apiQuery = 'api/resources/spaces-widget/'
  if(data.location && data.space_type!== 'virtual'){
    const [city, state] = data.location.split("|")
    apiQuery = 'api/resources/spaces-widget/?location__city__contains=' + city + '&location__state__contains=' + state
  }
  let spaces = await api.post(apiQuery,{
    start_datetime: data.start_date,
    end_datetime: data.end_date,
    space_type: data.space_type,
    max_capacity: data.seating_capacity,
    layout: data.layout
  })
    .then(([success, response]) => {
      return response?.data
    })
    .catch(err => {
      console.log(err)
    })
  return spaces
}

const getCities = async() => {
  let cities = await api.get('api/resources/spaces-widget/cities')
    .then(([success, response]) => {
      return response?.data
    })
    .catch(err => {
      console.log(err)
    })
  return cities
}

const lockSpace = async (lockedSpace) => {
  let data = {
    start_datetime: lockedSpace.start_date,
    end_datetime: lockedSpace.end_date,
  }
  if(lockedSpace.space_type === 'external'){
    data.external_space_id = lockedSpace.uid
    return lockedSpace.uid
  }
  else {
    if(lockedSpace.space_type === 'internal'){
      data.internal_space_id = lockedSpace.uid
    }
    if(lockedSpace.space_type === 'virtual'){
      data.virtual_space_id = lockedSpace.uid
    }
    const spaceLocked = await api.put('api/resources/spaces-widget/', data)
      .then(([success, response]) => {
        if(success){
          return true
        } else{
          return false
        }
      })
      .catch(err => {
        console.log(err)
      })
    return spaceLocked ? lockedSpace.uid : null
  }
}

const unlockSpace = async (unlockedSpace) => {
  let data = {
    start_datetime: unlockedSpace.start_date,
    end_datetime: unlockedSpace.end_date,
    internal_space_id: unlockedSpace.uid
  }
  if(unlockedSpace.space_type === 'external'){
    data.external_space_id = unlockedSpace.uid
    return true
  }
  else{
    if(unlockedSpace.space_type === 'internal'){
      data.internal_space_id = unlockedSpace.uid
    }
    if(unlockedSpace.space_type === 'virtual'){
      data.virtual_space_id = unlockedSpace.uid
    }
    let space = await api.del('api/resources/spaces-widget/',data)
      .then(([success, response]) => {
        if(success){
          return true
        } else{
          return false
        }
      })
      .catch(err => {
        console.log(err)
        return false
      })
    return space
  }
}

// Locked spaces storage
class LockedSpacesBackup {
  constructor(parent) {
    this.parent = parent
  }

  spaces = {};

  save = () => {
    for (let uid in this.parent.spaces) {
      this.spaces[uid] = this.parent.spaces[uid]
    }
  };

  clear = () => {
    this.spaces = {}
  };

  all = () => {
    let spaces = []
    for (let uid in this.spaces) {
      spaces.push(this.spaces[uid])
    }
    return spaces
  };

  uids = () => {
    let uids = []
    for (let uid in this.spaces) {
      uids.push(uid)
    }
    return uids
  };

  includes = uid => {
    return uid in this.spaces
  };

  any = () => {
    for (let uid in this.spaces) {
      return true
    }
    return false
  };
}

class LockedSpaces {
  constructor() {
    this.backup = new LockedSpacesBackup(this)
  }

  spaces = {};

  add = space => {
    this.spaces[space.uid] = space
  };

  del = space => {
    delete this.spaces[space.uid]
  };

  clear = () => {
    this.spaces = {}
    this.backup.clear()
  };

  get = uid => {
    return this.spaces[uid]
  };

  all = () => {
    let spaces = []
    for (let uid in this.spaces) {
      spaces.push(this.spaces[uid])
    }
    return spaces
  };

  widgetData = () => {
    let spaces = []
    for (let uid in this.spaces) {
      spaces.push(this.spaces[uid].widgetData)
    }
    return spaces
  };

  uids = () => {
    let uids = []
    for (let uid in this.spaces) {
      uids.push(uid)
    }
    return uids
  };

  includes = uid => {
    return uid in this.spaces
  };

  any = () => {
    for (let uid in this.spaces) {
      return true
    }
    return false
  };
}

const _lockedSpaces = new LockedSpaces()


// Requests tokens
let request1 = { cancel: function () { } }
let request2 = { cancel: function () { } }


// Main function
export default function Widget(props) {
  /* Input Hidden
   * Expected Props:
   * 
   * field = <Field JSON> Required
   * fieldData = <Field JSON> Required
   * setFieldValue = <function()> Required
   * 
   * locationField = <Field JSON> Required
   * timezoneField = <Field JSON> Required
   * startDateField = <Field JSON> Required
   * startTimeField = <Field JSON> Required
   * endDateField = <Field JSON> Required
   * endTimeField = <Field JSON> Required
   * seatingCapacityField = <Field JSON> Required
   */

  // Input Data
  const [location, setLocation] = React.useState(false)
  const [timezone, setTimezone] = React.useState(false)
  const [startDate, setStartDate] = React.useState(false)
  const [endDate, setEndDate] = React.useState(false)
  const [seatingCapacity, setSeatingCapacity] = React.useState(false)
  const [layout, setLayout] = React.useState(false)
  const [spaceType, setSpaceType] = React.useState(false)
  // Widget Data
  const [buildings, setBuildings] = React.useState([])
  const [features, setFeatures] = React.useState([])
  const [spaces, setSpaces] = React.useState([])
  const [cities, setCities] = React.useState([])
  // Filters Data
  const [buildingsIds, setBuildingsIds] = React.useState([])
  const [featuresIds, setFeaturesIds] = React.useState([])
  const [citiesIds, setCitiesIds] = React.useState([])
  // Search
  const searchKey = "name"
  const [searchValue, setSearchValue] = React.useState("")
  // Requests
  const [requests, setRequests] = React.useState(0)
  const reloadWidget = () => setRequests(requests + 1)
  // Flags
  const [isDataValid, setIsDataValid] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(props.isEditing !== true)

  // INITIALIZATION
  // Unlock spaces on Widget unmount
  React.useEffect(() => {
    return () => {
      // unlockSpaces(); // TODO: THIS WILL UNLOCK SPACES ON FORM SUBMIT
      // Clear local variables
      _lockedSpaces.clear()
      request1 = { cancel: function () { } }
      request2 = { cancel: function () { } }
    }
  }, [])

  React.useEffect(() => {
    if(props.isEditing){
      setIsLoading(false)
    }
  }, [props.isEditing])

  // (Re)load inital Widget data
  React.useEffect(() => {
    if (isDataValid) {
      // Get inital Widget data
      request1 = getWidget()
    } else {
      // Backup locked spaces
      _lockedSpaces.backup.save()
      // Unlock spaces
      unlockSpaces()
    }
    // Cancel current requests when data changes
    return () => { request1.cancel(); request2.cancel() }
  }, [isDataValid, location, timezone, startDate, endDate, seatingCapacity, layout])

  // (Re)load spaces and features
  React.useEffect(() => {
    if (isDataValid) {
      // Get spaces and features
      request2 = updateWidget()
    }
    // Cancel current requests when data changes
    return () => { request2.cancel() }
  }, [requests])

  // VALIDATIONS
  // Check input data
  React.useEffect(() => {
    if (
      props.locationField.valid !== false && // Location field is not required, but must be valid
      props.timezoneField.valid !== false && props.timezoneField.value &&
      props.startDateField.valid !== false && props.startDateField.value &&
      props.startTimeField.valid !== false && props.startTimeField.value &&
      props.endDateField.valid !== false && props.endDateField.value &&
      props.endTimeField.valid !== false && props.endTimeField.value &&
      props.seatingCapacityField.valid !== false && props.seatingCapacityField.value &&
      props.layout?.valid !== false
    ) {
      // Get new data
      const nextLocation = props.locationField.value
      const nextTimezone = props.timezoneField.value
      const nextStartDate = props.startDateField.value + " " + props.startTimeField.value
      const nextEndDate = props.endDateField.value + " " + props.endTimeField.value
      const nextSeatingCapacity = props.seatingCapacityField.value
      const nextLayout = props.layout.value
      // Save new data
      if (location !== nextLocation) { setLocation(nextLocation) }
      if (timezone !== nextTimezone) { setTimezone(nextTimezone) }
      if (startDate !== nextStartDate) { setStartDate(nextStartDate) }
      if (endDate !== nextEndDate) { setEndDate(nextEndDate) }
      if (seatingCapacity !== nextSeatingCapacity) { setSeatingCapacity(nextSeatingCapacity) }
      if (layout !== nextLayout) { setLayout(nextLayout) }
      if (props.spaceType) { setSpaceType(props.spaceType) }
      if (!isDataValid) { setIsDataValid(true) }
    } else {
      if (isDataValid) { setIsDataValid(false) }
    }
  }, [
    props.locationField.valid, props.locationField.value,
    props.timezoneField.valid, props.timezoneField.value,
    props.startDateField.valid, props.startDateField.value,
    props.startTimeField.valid, props.startTimeField.value,
    props.endDateField.valid, props.endDateField.value,
    props.endTimeField.valid, props.endTimeField.value,
    props.seatingCapacityField.valid, props.seatingCapacityField.value,
    props.layout?.valid, props.layout?.value
  ])

  // Save lockedSpaces uids to field value
  const nextDataValue = _lockedSpaces.uids()
  React.useEffect(() => {
    if (JSON.stringify(props.fieldData.value) !== JSON.stringify(nextDataValue)) {
      props.setFieldValue(nextDataValue)
    }
  }, [JSON.stringify(nextDataValue), props.fieldData.value])

  // FUNCTIONS
  const lockSpaceHandler = async (spaceId, space = undefined) => {
    // Create Lock Request
    let lockedSpace = {
      uid: spaceId,
      lock: true,
      start_date: startDate,
      end_date: endDate,
      //search_key: searchKey,
      //search_value: searchValue,
      timezone: timezone,
      location: location,
      seating_capacity: seatingCapacity,
      from_form: true,
      space_type: spaceType
    }
    // Send Lock Request
    const lockedId = await lockSpace(lockedSpace)
    // Check request result
    if (lockedId !== spaceId) {
      console.error("Form Widget Error: Requested to lock space with the uid: " + spaceId + ", and server retuned uid: " + lockedId)
      return
    }
    // Save result
    lockedSpace.widgetData = space || spaces[spaces.findIndex(s => s.uuid === spaceId)]
    const updatedSpaces = spaces.filter(s => s.uuid !== spaceId)
    setSpaces(updatedSpaces)
    _lockedSpaces.add(lockedSpace)
    // Reload widget
    reloadWidget()
  }

  const lockSpaces = async selectedSpaces => {
    let lockedSpace
    for (let space of selectedSpaces) {
      // Create Lock Request
      lockedSpace = {
        uid: space.uuid,
        lock: true,
        start_date: startDate,
        end_date: endDate,
        //search_key: searchKey,
        //search_value: searchValue,
        timezone: timezone,
        location: location,
        seating_capacity: seatingCapacity,
        from_form: true,
        space_type: spaceType
      }
      // Send Lock Request
      const lockedId = await lockSpace(lockedSpace)
      // Check request result
      if (lockedId !== space.uuid) {
        console.error("Form Widget Error: Requested to lock space with the uid: " + space.uid + ", and server retuned uid: " + lockedId)
      }
      // Save result
      else {
        lockedSpace.widgetData = space
        const updatedSpaces = spaces.filter(s => s.uuid !== lockedId)
        setSpaces(updatedSpaces)
        _lockedSpaces.add(lockedSpace)
      }
    }
  }

  const unlockSpaceHandler = async (spaceId, lockedSpace = undefined) => {
    // Check lockedSpace
    if (!lockedSpace) {
      lockedSpace = _lockedSpaces.get(spaceId)
    }
    // Create Unlock Request
    let unlockedSpace = {
      uid: lockedSpace.uid,
      start_date: lockedSpace.start_date,
      end_date: lockedSpace.end_date,
      space_type: spaceType
    }
    // Send Unlock Request
    const isDeleted = await unlockSpace(unlockedSpace)
    // Check request result
    if (!isDeleted) {
      console.error("Form Widget Error: Requested to unlock space with the uid: " + spaceId + ", and server retuned uid: " + spaceId)
      return
    }
    // Save result
    _lockedSpaces.del(lockedSpace)
    let initialSpaces = await getSpaces({
      start_date: startDate,
      end_date: endDate,
      seating_capacity: seatingCapacity,
      space_type: spaceType,
      layout: layout,
      location: location
    })
    initialSpaces = initialSpaces.map(item => ({...item, spaceType: spaceType}))
    setSpaces(initialSpaces)
    // Reload widget
    reloadWidget()
  }

  const unlockSpaces = async (spaces = undefined) => {
    let unlockedSpace
    for (let lockedSpace of _lockedSpaces.all()) {
      // Create Unlock Request
      unlockedSpace = {
        uid: lockedSpace.uid,
        start_date: lockedSpace.start_date,
        end_date: lockedSpace.end_date,
        //search_key: lockedSpace.search_key,
        //search_value: lockedSpace.search_value,
        timezone: lockedSpace.timezone,
        location: lockedSpace.location,
        seating_capacity: lockedSpace.seating_capacity,
        from_form: true,
        space_type: spaceType
      }
      // Send Unlock Request
      const unlockedId = await unlockSpace(unlockedSpace)
      // Check request result
      if (!unlockedId) {
        console.error("Form Widget Error: Requested to unlock space with the uid: " + lockedSpace.uid + ", and server retuned uid: " + unlockedId)
      }
      // Save result
      else {
        _lockedSpaces.del(lockedSpace)
      }
    }
  }

  const getWidget = () => {
    // Cancel listener
    let wasCancelled = false
    const returningObj = {
      cancel: function () { wasCancelled = true }
    }
    // Set isLoading flag
    if (!isLoading) { setIsLoading(true) }

    // UNLOCK SPACES
    // Check if request was not cancelled
    if (wasCancelled) {
      if (window.eventops?.showDebug) {
        console.debug("\u2716 Form Widget: Cancelled getWidget (" +
          location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
      }
      return returningObj
    }
    // Backup locked spaces
    _lockedSpaces.backup.save()
    // Unlock spaces
    unlockSpaces().then(async () => {

      // LOAD BUILDINGS
      // Check if request was not cancelled
      if (wasCancelled) {
        if (window.eventops?.showDebug) {
          console.debug("\u2716 Form Widget: Cancelled getWidget (" +
            location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
        }
        setIsLoading(false)
        return returningObj
      }
      // Get Building
      // const nextBuildings = await getBuildings({
      //   start_date: startDate,
      //   end_date: endDate,
      //   timezone: timezone,
      //   location: location,
      //   seating_capacity: seatingCapacity,
      //   from_form: true
      // })
      // // Get Buildings Ids
      // const allBuildingsIds = nextBuildings.map(building => building.uid)
      // let nextBuildingsIds = [...buildingsIds]
      // // Check if Buildings in filter are still valid
      // if (nextBuildingsIds.length) {
      //   for (let index in nextBuildingsIds) {
      //     // Remove invalid Building
      //     if (!allBuildingsIds.includes(nextBuildingsIds[index])) {
      //       nextBuildingsIds.splice(index, 1)
      //     }
      //   }
      // }
    let initialSpaces = await getSpaces({
      start_date: startDate,
      end_date: endDate,
      seating_capacity: seatingCapacity,
      space_type: spaceType,
      layout: layout,
      location: location
    })
    initialSpaces = initialSpaces.map(item => ({...item, spaceType: spaceType}))
    setSpaces(initialSpaces)
    const cities = await getCities()
    setCities(cities)
    const buildings = initialSpaces?.map(space => {
      const building = {
        uid: space.location_id,
        name: space.location__name,
        label: space.location__name,
        value: space.location_id
      }
      return building
    })
    const nextBuildings = buildings?.filter( (ele, ind) => ind === buildings.findIndex( elem => elem.uid === ele.uid && elem.name === ele.name))
    // setBuildings(buildings)
    // Get Buildings Ids
    const allBuildingsIds = nextBuildings?.map(building => building.uid)
    let nextBuildingsIds = [...buildingsIds]
    // Check if Buildings in filter are still valid
    if (nextBuildingsIds.length) {
      for (let index in nextBuildingsIds) {
        // Remove invalid Building
        if (!allBuildingsIds.includes(nextBuildingsIds[index])) {
          nextBuildingsIds.splice(index, 1)
        }
      }
    }
    setIsLoading(false)

      // LOCK SPACES
      // Check if request was not cancelled
      if (wasCancelled) {
        if (window.eventops?.showDebug) {
          console.debug("\u2716 Form Widget: Cancelled getWidget (" +
            location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
        }
        return returningObj
      }
      // Try to lock spaces again
      if (_lockedSpaces.backup.any()) {
        // Get Spaces
        let allSpaces = await getSpaces({
          start_date: startDate,
          end_date: endDate,
          seating_capacity: seatingCapacity,
          space_type: spaceType,
          layout: layout,
          location: location
        })
        allSpaces = allSpaces.map(item => ({...item, spaceType: spaceType}))
        setSpaces(allSpaces)
        // Check if request was not cancelled
        if (wasCancelled) {
          if (window.eventops?.showDebug) {
            console.debug("\u2716 Form Widget: Cancelled getWidget (" +
              location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
          }
          return returningObj
        }
        // Lock spaces
        await lockSpaces(
          allSpaces?.filter(space => _lockedSpaces.backup.includes(space.uuid))
        )
      }

      // SAVE DATA
      // Check if request was not cancelled
      if (wasCancelled) {
        if (window.eventops?.showDebug) {
          console.debug("\u2716 Form Widget: Cancelled getWidget (" +
            location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
        }
        return returningObj
      }
      if (window.eventops?.showDebug) {
        console.debug("\u2714 Form Widget: Succesfully getWidget (" +
          location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
      }
      // Save results
      setBuildings(nextBuildings)
      setBuildingsIds(nextBuildingsIds)
      // Reload widget
      reloadWidget()
    })

    // Return function to cancel this function
    return returningObj
  }

  const updateWidget = () => {
    // Cancel listener
    let wasCancelled = false
    const returningObj = {
      cancel: function () { wasCancelled = true }
    }
    // Set isLoading flag
    if (!isLoading) { setIsLoading(true) }

    // CHECK BUILDINGS
    // if (!buildings.length) {
    //   // Clear features and spaces
    //   setFeatures([])
    //   setSpaces([])
    //   setFeaturesIds([])
    //   // Unset isLoading flag
    //   setIsLoading(false)
    //   // Clear locked spaces backup
    //   _lockedSpaces.backup.clear()
    //   return returningObj
    // }

    // LOAD FEATURES
    // Check if request was not cancelled
    if (wasCancelled) {
      if (window.eventops?.showDebug) {
        console.debug("\u2716 Form Widget: Cancelled updateWidget (" +
          location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
      }
      return returningObj
    }
    // Get Features
    // getFeatures({
    //   building_id: (buildingsIds.length && buildingsIds) || buildings.map(building => building.uid),
    //   start_date: startDate,
    //   end_date: endDate
    // }).then(async nextFeatures => {
    //   // Get Features Ids
    //   const allFeaturesIds = nextFeatures.map(feature => feature.uid)
    //   let nextFeaturesIds = [...featuresIds]
    //   // Check if Featuress in filter are still valid
    //   if (nextFeaturesIds.length) {
    //     for (let index in nextFeaturesIds) {
    //       // Remove invalid Feature
    //       if (!allFeaturesIds.includes(nextFeaturesIds[index])) {
    //         nextFeaturesIds.splice(index, 1)
    //       }
    //     }
    //   }

      // LOAD SPACES
      // Check if request was not cancelled
      if (wasCancelled) {
        if (window.eventops?.showDebug) {
          console.debug("\u2716 Form Widget: Cancelled updateWidget (" +
            location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
        }
        return returningObj
      }
      // Get Spaces
      // const nextSpaces = getSpaces({
      //   building_id: (buildingsIds.length && buildingsIds) || buildings.map(building => building.uid),
      //   feature_id: (nextFeaturesIds.length && nextFeaturesIds) || [],
      //   start_date: startDate,
      //   end_date: endDate,
      //   //search_key: searchKey, // Doing client-side searching
      //   //search_value: searchValue, // Doing client-side searching
      //   timezone: timezone,
      //   location: location,
      //   seating_capacity: seatingCapacity,
      //   from_form: true
      // })

    //   // SAVE DATA
    //   // Check if request was not cancelled
      if (wasCancelled) {
        if (window.eventops?.showDebug) {
          console.debug("\u2716 Form Widget: Cancelled updateWidget (" +
            location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
        }
        return returningObj
      }
      if (window.eventops?.showDebug) {
        console.debug("\u2714 Form Widget: Succesfully updateWidget (" +
          location + ", from " + startDate + " to " + endDate + " and " + seatingCapacity + " attendee(s)).")
      }
    //   // Save results
    //   setFeatures(nextFeatures)
    //   setSpaces(nextSpaces)
    //   setFeaturesIds(nextFeaturesIds)
    //   // Unset isLoading flag
      setIsLoading(false)
    //   // Clear locked spaces backup
      _lockedSpaces.backup.clear()
    // })
    setIsLoading(false)
    // Return function to cancel this function
    return returningObj
  }

  // Check if should render
  if (!props.isEditing && (!props.fieldData.render || !isDataValid)) {
    return null
  }

  // Get validations class
  let validationText = ""
  let validationClass = ""
  if (props.fieldData.valid === true && !isLoading) {
    validationText = (
      <div className="text-success">
        <small>{props.field.validation.validMessage}</small>
      </div>
    )
    validationClass = " border-success"
  } else if (props.fieldData.valid === false && !isLoading) {
    validationText = (
      <div className="text-danger">
        <small>{props.fieldData.invalidMessage || props.field.validation.invalidMessage}</small>
      </div>
    )
    validationClass = " border-danger"
  }

  const filteredSpaces = () => {
    let updatedSpaces = spaces?.filter(space => space.name.toLowerCase().includes(searchValue.toLowerCase()))
    if(buildingsIds.length){
      updatedSpaces = updatedSpaces.filter(item => buildingsIds.includes(item.location_id))
    }
    if(citiesIds.length){
      updatedSpaces = updatedSpaces.filter(item => citiesIds.includes(item.location__value))
    }
    return updatedSpaces
  }

  // Render Widget
  return (
    <div className="form-group">
      <label>{props.field.label}</label>
      {props.field.help && props.field.helpAboveInput &&
        <small className="form-text text-muted mt-0 mb-2 custom-field-help">{props.field.help}</small>
      }
      <div className={"widget-wrapper" + validationClass}>
        <div className="widget-filters-wrapper">
          {!location ? 
          <div className="form-group widget-filter-wrapper">
            <label>Cities:</label>
            <WidgetFilter
              placeholder="Select cities"
              options={cities?.length && cities.sort(sortValues("name"))}
              value={citiesIds}
              onChange={value => { setCitiesIds(value); reloadWidget() }}
            />
          </div>
          : <></> }
          <div className="form-group widget-filter-wrapper">
            <label>Buildings:</label>
            <WidgetFilter
              placeholder="Select buildings"
              options={buildings.length && buildings?.sort(sortValues("name"))}
              value={buildingsIds}
              onChange={value => { setBuildingsIds(value); reloadWidget() }}
            />
          </div>
          {/* <div className="form-group widget-filter-wrapper">
            <label>Features:</label>
            <WidgetFilter
              placeholder="Select features"
              options={features.sort(sortValues("name"))}
              value={featuresIds}
              onChange={value => { setFeaturesIds(value); reloadWidget() }}
            />
          </div> */}
          <div className="form-group widget-search-wrapper">
            <label>Search by:</label>
            <WidgetSearch
              name="search_value"
              placeholder="Search by name"
              value={searchValue}
              onChange={setSearchValue}
            />
          </div>
        </div>
        <div className={`widget-result-wrapper${spaces?.length && !spaces?.filter(space => space.name.toLowerCase().includes(searchValue.toLowerCase())).length ? " widget-empty-table" : ""}`}>
          <WidgetResults
            // spaces={spaces.length && spaces?.filter(space => space.name.toLowerCase().includes(searchValue.toLowerCase()))}
            spaces={spaces?.length && filteredSpaces()}
            button={(text, record, index) =>
              <ButtonAdd
                title="Add Space"
                className="btn-sm"
                onClick={() => lockSpaceHandler(record.key)}
              />
            }
            noDataText={props.field.noDataText}
            isLoading={isLoading}
          />
        </div>
      </div>
      {validationText}
      <br />
      <label>{props.field.label2}</label>
      <div className={`${!_lockedSpaces.widgetData().filter(space => space.spaceType === spaceType).length ? " widget-empty-table" : ""} widget-selected-wrapper` + validationClass}>
        <WidgetResults
          spaces={_lockedSpaces.widgetData().filter(space => space.spaceType === spaceType)}
          button={(text, record, index) =>
            <ButtonCancel
              title="Remove Space"
              className="btn-sm"
              onClick={() => unlockSpaceHandler(record.key)}
            />
          }
          noDataText={props.field.noDataText}
          isLoading={isLoading}
        />
      </div>
      {validationText}
      {props.field.help && !props.field.helpAboveInput &&
        <small className="form-text text-muted custom-field-help">{props.field.help}</small>
      }
    </div>
  )
}

Widget.defaultProps = {
  field: {},
  isEditing: false,
  fieldData: { valid: null },
  setFieldValue: () => { },
  locationField: { valid: false },
  timezoneField: { valid: false },
  startDateField: { valid: false },
  startTimeField: { valid: false },
  endDateField: { valid: false },
  endTimeField: { valid: false },
  seatingCapacityField: { valid: false },
}


// Filters
function WidgetFilter(props) {
  const value = props?.options?.length && props.options.filter(o => props.value.includes(o.uid || o.value)).map(o => ({
    key: o.uid || o.value,
    label: o.name || o.label,
    value: o.uid || o.value,
  }))

  // FUNCTIONS
  const onChange = newValue => {
    props.onChange(newValue.map(v => v.value))
  }

  // RENDER
  return (
    <Select
      showArrow
      allowClear
      labelInValue
      mode="multiple"
      notFoundContent=""
      placeholder={props.placeholder}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1}
      style={{ width: '100%' }}
    >
      {props?.options?.length && props.options.filter((item, index) => !props.value.includes(item.uid || item.value)).map(item => (
        <Select.Option key={item.uid || item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}


function WidgetSearch(props) {
  return (
    <Input.Search
      allowClear
      value={props.value}
      placeholder={props.placeholder}
      onChange={s => props.onChange(s.target.value)}
      style={{ width: '100%', height: '32px' }}
    />
  )
}


// Table
function WidgetResults(props) {
  const loading = {
    spinning: props.isLoading,
    indicator: <ProgressSpinner className="widget-loading-indicator" />,
  }

  const columns = [
    {
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      className: "widget-column widget-column-btn",
      render: props.button
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: props.spaces?.length ? 'left' : null,
      className: "widget-column widget-column-name",
      sorter: sortValues('name'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Building',
      dataIndex: 'location__name',
      key: 'location__name',
      className: "widget-column widget-column-location__name",
      sorter: sortValues('location__name'),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Floor',
      dataIndex: 'floor__name',
      key: 'floor__name',
      sorter: sortValues('floor__name'),
      className: "widget-column widget-column-floor__name",
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Capacity',
      dataIndex: 'max_capacity',
      key: 'max_capacity',
      className: "widget-column widget-column-capacity",
      sorter: sortValues('max_capacity'),
      sortDirections: ['ascend', 'descend']
    },
  ]

  return <Table
    columns={columns}
    dataSource={props.spaces?.length && props.spaces.map(space => {
      return ({
        key: space.uuid,
        name: space.name,
        location__name: space.location__name,
        floor__name: space.floor__name,
        max_capacity: space.max_capacity || space.layout_max_capacity,
      })
    })}
    loading={loading}
    locale={{ emptyText: props.noDataText }}
    pagination={false}
    size="middle"
    scroll={{ x: "max-content", y: 480 }}
    style={{ width: '100%', margin: 0 }}
  />
}


// TOOLS

function sortValues(key = 'label') {
  return (a, b) => {
    // Get values to compare
    let valueA = a[key]
    let valueB = b[key]
    if (typeof valueA === "string") {
      valueA = valueA.toUpperCase()
    }
    if (typeof valueB === "string") {
      valueB = valueB.toUpperCase()
    }
    // Compare values
    let comparison = 0
    if (valueA > valueB) {
      comparison = 1
    } else if (valueA < valueB) {
      comparison = -1
    }
    // Return result
    return comparison
  }
}
