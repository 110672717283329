import React from 'react'
import BaseField from './BaseField'


export default function FieldText(props) {
  /* Input Text
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * maxLength = <integer> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */

  // RENDER
  const input = (
    <input
      ref={props.inputRef}
      className={
        "form-control custom-field-input" + (
          props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
        )
      }
      name={props.name}
      type="text"
      placeholder={props.placeholder}
      value={props.value || (props.value !== null && props.value !== undefined ? props.value : "")}
      required={props.required && props.valid === false}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
      readOnly={props.readOnly}
      autoFocus={props.autoFocus}
      maxLength={props.maxLength === undefined ? 150 : props.maxLength}
    />
  )

  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={input}
      prepend={props.prepend}
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
