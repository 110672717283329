import React from 'react'
import BaseField from './BaseField'


export default function FieldNumber(props) {
  /* Input Number
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * step = <string> Optional
   * min = <integer> Optional
   * max = <integer> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */
  const value = ["number", "string"].includes(typeof props.value) ? props.value : ""
  const minValue = isNaN(props.min) || props.min === null ? "" : props.min
  const maxValue = isNaN(props.max) || props.max === null ? "" : props.max

  // EFFECTS
  React.useEffect(() => {
    if (value !== "" && minValue !== "" && Number(value) < Number(minValue)) {
      const delayedChange = setTimeout(() => props.onChange({ target: { value: minValue } }), 500)
      return () => clearTimeout(delayedChange)
    } else if (value !== "" && maxValue !== "" && Number(value) > Number(maxValue)) {
      const delayedChange = setTimeout(() => props.onChange({ target: { value: maxValue } }), 500)
      return () => clearTimeout(delayedChange)
    }
  }, [value, props.min, props.max])

  // FUNCTIONS
  const onChange = event => {
    // Process changes ONLY if valid (ignoring max/min values)
    if (event.target.validity.valid || event.target.validity.rangeUnderflow || event.target.validity.rangeOverflow) {
      props.onChange(event)
    }
  }

  const onBlur = event => {
    // If NOT valid
    if (!event.target.validity.valid) {
      // If bad value is saved to state or trying to empty a required field
      if (event.target.value === value || event.target.validity.valueMissing) {
        // Set value as empty string
        event.target.value = ""
        props.onChange(event)
      }
    }
    props.onBlur?.(event)
  }

  // RENDER
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      prepend={props.prepend}
      input={
        <input
          ref={props.inputRef}
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          name={props.name}
          type="number"
          placeholder={props.placeholder}
          value={value}
          required={props.required && props.valid === false}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onBlur}
          disabled={props.disabled}
          readOnly={props.readOnly}
          autoFocus={props.autoFocus}
          step={props.step}
          min={minValue}
          max={maxValue}
        />
      }
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
