import React from 'react'
import api from 'libs/api'
import { Spinner } from 'components/spinner'
import BaseField from './BaseField'
import FieldDropdown from './FieldDropdown'
import FieldDatalist from './FieldDatalist'


// TODO: CLEAN UP THIS CODE -> SHARE GET FIELD FUNCTION FROM FORMS EDITOR

const getFieldValues = async (mapField, request = null) => {
  // Get Data
  const apiPath = "field/" + mapField + "/"
  const [responseOk, responseData] = request === null ?
    await api.get(apiPath)
    :
    await api.post(apiPath, request)
  // Check response
  if (responseOk) {
    // Return values
    return responseData
  }
  // Return empty values
  return []
}

const getAdminFieldValues = async fieldApi => {
  // Get Data
  const apiPath = "field/"
  const [responseOk, responseData] = await api.post(apiPath, fieldApi)
  // Check response
  if (responseOk) {
    // Return values
    return responseData
  }
  // Return empty values
  return []
}


export default function FieldFetch(props) {
  /* Input Fetch (Dropdown)
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * type = <string> ("dropdown", "datalist") Optional
   * api = {
   *   method = <string> Required
   *   url = <string> Required
   *   authorization = <string> Optional
   *   request = <Object> Optional
   *   response = {
   *     array = [<string>] Required
   *     label = [<string>] Required
   *     value = [<string>] Required
   *   }
   * }
   * trigger = <any> Optional
   * inAdmin = <boolean> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */
  const [values, setValues] = React.useState([])
  const [rawValues, setRawValues] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)

  // INITIALIZATION
  const getValues = () => {
    // Cancel listener
    let wasCancelled = false
    const returningObj = {
      cancel: function () { wasCancelled = true }
    }
    // Set is loading flag
    setIsLoading(true)
    // Request values locally if user token is not required
    if (!props.api.authorization || !props.api.authorization.includes("{user_token}")) {
      const apiUrl = (props.api.url.startsWith("http") ? "" : api.url) + props.api.url
      const apiProps = {
        method: props.api.method || 'GET',
        mode: 'cors',
        ...(props.api.url.startsWith("http") ? {} : {
          credentials: 'include',
          referrerPolicy: 'origin',
        }),
        headers: {
          'Content-Type': 'application/json',
          ...(props.api.authorization ? {
            Authorization: props.api.authorization
          } : {})
        },
        body: props.api.method === "GET" ? undefined : JSON.stringify(props.api.request)
      }
      fetch(apiUrl, apiProps).then(async response => {
        // Check if was not cancelled
        if (wasCancelled) {
          return returningObj
        }
        // If request failed:
        if (response.status !== 200) {
          console.error("Failed to fetch data for field. Server response: " + response.statusText)
          setValues([])
          setIsLoading(false)
          return returningObj
        }
        // If not, get response payload
        const responseJson = await response.json()
        // Get list
        const objects = props.api.response.array.reduce((objects, index) => objects[index], responseJson)
        // Check list
        if (!Array.isArray(objects)) {
          console.error("Failed to fetch data for field. Could not get an array from response: " + JSON.stringify(objects))
          setValues([])
          setIsLoading(false)
          return returningObj
        }
        // Get values
        const values = objects.map(object => ({
          label: props.api.response.label.reduce((label, index) => label[index], object),
          value: props.api.response.value.reduce((value, index) => value[index], object),
          raw: [props.api.response.value.reduce((value, index) => value[index], object), object]
        }))
        // Check values
        if (values[0] && (values[0].label instanceof Object || values[0].value instanceof Object)) {
          console.error("Failed to fetch data for field. Could not get a valid value from response: " + JSON.stringify(values[0]))
          setValues([])
          setIsLoading(false)
          return returningObj
        }
        // Check if was not cancelled
        if (wasCancelled) {
          return returningObj
        }
        // Save results
        setValues(values.map(({ label, value }) => ({ label, value })))
        setRawValues(Object.fromEntries(values.map(({ raw }) => raw)))
        setIsLoading(false)
      }).catch(err => console.error(err))
    }
    // Request values from back end if user token is required
    else if (props.inAdmin) {
      getAdminFieldValues(props.api).then(values => {
        // Check if was not cancelled
        if (wasCancelled) {
          return returningObj
        }
        // Save results
        setValues(values)
        setIsLoading(false)
      })
    }
    else {
      getFieldValues(props.name, props.api.request).then(values => {
        // Check if was not cancelled
        if (wasCancelled) {
          return returningObj
        }
        // Save results
        setValues(values)
        setIsLoading(false)
      })
    }
    // Returning canceling function
    return returningObj
  }
  React.useEffect(() => {
    if (props.api && props.api.url && props.api.response) {
      if (/^(https:\/\/|api\/)[\S]+$/.test(props.api.url)) {
        const getValuesRequest = getValues()
        return getValuesRequest.cancel
      } else {
        console.error("Failed to fetch data for field. '" + props.api.url + "' is not a valid URL.")
        setValues([])
        setIsLoading(false)
      }
    } else {
      console.error("Failed to fetch data for field. Could not find necessary API data.")
      setValues([])
      setIsLoading(false)
    }
  }, [JSON.stringify(props.api), props.trigger])

  const onChange = ({ target: { value } }) => {
    if (value === undefined) value = ""
    props.onChange?.({ target: { value } }, rawValues[value])
  }

  // RENDER
  // If is loading
  if (isLoading) {
    const value = values.reduce((result, value) => {
      if (value.value === props.value) {
        return value.label
      }
      return result
    }, "Loading...")

    return <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        <div className="input-loading">
          <input
            className="form-control custom-field-input"
            name={props.name}
            type="text"
            value={value}
            readOnly={true}
          />
          <Spinner />
        </div>
      }
      required={props.required}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
    />
  }

  // Get generic props
  const fieldProps = {
    inputRef: props.inputRef,
    className: props.className,
    label: props.label,
    required: props.required,
    name: props.name,
    value: props.value || "",
    values: values,
    placeholder: props.placeholder,
    valid: props.valid,
    validMessage: props.validMessage,
    invalidMessage: props.invalidMessage,
    help: props.help,
    onChange: onChange,
    onBlur: props.onBlur,
    prepend: props.prepend,
    append: props.append,
    disabled: props.disabled,
    readOnly: props.readOnly,
    autoFocus: props.autoFocus,
    horizontal: props.horizontal,
    horizontalLabelSize: props.horizontalLabelSize,
    showEmpty: props.showEmpty,
    reorder: props.reorder
  }

  switch (props.type) {
    case "dropdown":
      return <FieldDropdown {...fieldProps} />
    case "datalist":
      return <FieldDatalist {...fieldProps} />
    default:
      return <FieldDropdown {...fieldProps} />
  }
}
