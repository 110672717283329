import { numberToString, dateToString, timeToString, datetimeToString } from './format'
import { isString } from './dataCheckers'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tag from 'components/tag'


export const renderBool = (value, showFalse = false) => value ? <FontAwesomeIcon className="icon-bool-true" icon={faCheckCircle} /> :
  (showFalse && value === false) ? <FontAwesomeIcon className="icon-bool-false" icon={faTimesCircle} /> : null

export const renderInt = numberToString

export const renderFloat = (value) => numberToString(value, true)

export const renderDate = dateToString

export const renderTime = timeToString

export const renderDatetime = datetimeToString

export const renderColor = (value) => (value && isString(value)) ?
  <div className="mx-auto w-100 h-20px color-box" style={{ 'background': value }} /> : "-"

export const renderByType = (value, type) => !type ? value :
  ["bool", "boolean"].includes(type) ? renderBool(value) :
    ["int", "number"].includes(type) ? renderInt(value) :
      type === "float" ? renderFloat(value) :
        type === "date" ? renderDate(value) :
          type === "time" ? renderTime(value) :
            type === "datetime" ? renderDatetime(value) :
              type === "color" ? renderColor(value) :
                type === "tags" ? Array.isArray(value) ? value.map((tagValue, key) => <Tag key={key} {...tagValue}/>) : <Tag {...value} />
                  : value
