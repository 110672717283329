import React from 'react'
import iconLogo from 'assets/images/eventopsIconLogoWhite.png'
import { Spinner } from 'components/spinner'
import Content from 'components/layout/Content'
import './index.scss'


export function LoadingRoot(props) {

  return (
    <div className="login-page">

      <div className="login-content">

        <div className="login-logo">
          <img src={iconLogo} />
        </div>

        <div className="loading">
          <Spinner />
        </div>

      </div>

      <div className="login-footer">
        &copy; {new Date().getFullYear()} Elevar Technologies, Inc.
      </div>

    </div >
  )
}

export default function Loading(props) {

  return <Content title="Loading..." />
}
