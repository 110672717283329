import { isDict } from './dataCheckers'

export function copyDeep(source) {
  let sourceCopy

  if (source instanceof Object && !(source instanceof File) && !(typeof source === 'function')) {
    if (Array.isArray(source)) {
      sourceCopy = []
    } else {
      sourceCopy = {}
    }
    for (let key in source) {
      sourceCopy[key] = copyDeep(source[key])
    }
  } else {
    sourceCopy = source
  }
  return sourceCopy
}

// No in use. Check if working before use.
export function mergeDeep(target, ...sources) {

  if (!sources.length) {
    return target
  }

  const source = sources.shift()

  if (isDict(target) && isDict(source)) {
    for (const key in source) {
      if (isDict(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          })
        }
        mergeDeep(target[key], source[key])
      }
      else {
        Object.assign(target, {
          [key]: source[key]
        })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export function includesKeyValue(array, key, value) {
  return array.findIndex(item => item[key] === value) !== -1
}
