/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import messenger from 'libs/messenger'

const unauthorizedMessage = "Please try to login again or contact a staff member to know more about this issue."
const forbidenMessage = "You don't have permission to do this request. Please contact a staff member to know more about this issue."
const errorMessage = "Please contact a staff member to know more about this issue."

const urlRegex = new RegExp("^https?:\\/\\/\\S+$")
const isUrl = str => urlRegex.test(str)

// API CALLS
class Api {
  constructor() {
    const hostname = window.location.hostname.split(/[.|:]/)
    this.url =
      // Local
      hostname[1] === 'local'
        ? `https://${hostname[0]}.local.eventops.events/`
        : // Development
        (hostname[1] === 'dev'
          ? `https://${hostname[0]}.dev.eventops.events/`
          : // QA
          (hostname[1] === 'qa'
            ? `https://${hostname[0]}.qa.eventops.events/`
            : // Stage
            (hostname[1] === 'stage'
              ? `https://${hostname[0]}.stage.eventops.events/`
              : // Production
              (hostname[1] === 'eventops'
                ? `https://${hostname[0]}.eventops.events/`
                : // If can't define, use Development
                `https://${hostname[0]}.dev.eventops.events/`))))
    this.lastUnauthorizedMessage = null
    this.lastForbiddenMessage = null
  }

  getApiUrl(path) {
    return isUrl(path) ? path : this.url + path
  }

  async get(path, headers = {}, timeout = 10000) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), timeout)
    const response = await fetch(this.getApiUrl(path), {
      method: 'GET',
      mode: 'cors',
      signal: controller.signal,
      credentials: 'include',
      referrerPolicy: 'origin',
      headers: headers
    })
      .then((response) => this.processResponse(response, 'GET from ' + path))
      .catch((error) => this.processError(error, 'GET from ' + path))
    return response
  }

  async post(path, body = {}, headers = {}, timeout = 10000) {
    [body, headers] = this.processRequest(body, headers)
    const controller = new AbortController()
    setTimeout(() => controller.abort(), timeout)
    const response = await fetch(this.getApiUrl(path), {
      method: 'POST',
      mode: 'cors',
      signal: controller.signal,
      credentials: 'include',
      referrerPolicy: 'origin',
      headers: headers,
      body: body
    })
      .then((response) => this.processResponse(response, 'POST to ' + path))
      .catch((error) => this.processError(error, 'POST to ' + path))
    return response
  }

  async patch(path, body = {}, headers = {}, timeout = 10000) {
    [body, headers] = this.processRequest(body, headers)
    const controller = new AbortController()
    setTimeout(() => controller.abort(), timeout)
    const response = await fetch(this.getApiUrl(path), {
      method: 'PATCH',
      mode: 'cors',
      signal: controller.signal,
      credentials: 'include',
      referrerPolicy: 'origin',
      headers: headers,
      body: body
    })
      .then((response) => this.processResponse(response, 'PATCH to ' + path))
      .catch((error) => this.processError(error, 'PATCH to ' + path))
    return response
  }

  async put(path, body = {}, headers = {}, timeout = 10000) {
    [body, headers] = this.processRequest(body, headers)
    const controller = new AbortController()
    setTimeout(() => controller.abort(), timeout)
    const response = await fetch(this.getApiUrl(path), {
      method: 'PUT',
      mode: 'cors',
      signal: controller.signal,
      credentials: 'include',
      referrerPolicy: 'origin',
      headers: headers,
      body: body
    })
      .then((response) => this.processResponse(response, 'PUT to ' + path))
      .catch((error) => this.processError(error, 'PUT to ' + path))
    return response
  }

  async del(path, headers = {}, timeout = 10000) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), timeout)
    const response = await fetch(this.getApiUrl(path), {
      method: 'DELETE',
      mode: 'cors',
      signal: controller.signal,
      credentials: 'include',
      referrerPolicy: 'origin',
      headers: headers
    })
      .then((response) => this.processResponse(response, 'DELETE to ' + path))
      .catch((error) => this.processError(error, 'DELETE to ' + path))
    return response
  }

  processRequest = (body, headers) => {
    if (body instanceof FormData) {
      return [body, headers]
    }
    return [
      JSON.stringify(body),
      { ...{ 'Content-Type': 'application/json' }, ...headers }
    ]
  }

  processResponse = async (response, requestName) => {
    const responseData = await response.json().catch(() => null)
    const responseMessages = responseData?.messages && typeof responseData.messages === 'object' ?
      responseData.messages : null
    if (responseMessages) {
      if (responseMessages.success) {
        messenger.showSuccess(responseMessages.success)
      }
      if (responseMessages.info) {
        messenger.showInfo(responseMessages.info)
      }
      if (responseMessages.error) {
        messenger.showError(responseMessages.error)
      }
      if (responseMessages.console) {
        console.info(responseMessages.console)
      }
    }
    if (!response.ok) {
      if (response.status === 401) {
        if (!responseMessages?.error) {
          const now = new Date()
          if (now > this.lastUnauthorizedMessage) {
            messenger.showError('Unauthorized', unauthorizedMessage)
            // Add 3 seconds and save as last Unauthorized message
            now.setSeconds(now.getSeconds() + 3)
            this.lastUnauthorizedMessage = now
          }
        }
      } else if (response.status === 403) {
        if (!responseMessages?.error) {
          const now = new Date()
          if (now > this.lastForbiddenMessage) {
            messenger.showError('Forbidden', forbidenMessage)
            // Add 3 seconds and save as last Forbidden message
            now.setSeconds(now.getSeconds() + 3)
            this.lastForbiddenMessage = now
          }
        }
      } else if (response.status === 400) {
        if (!responseMessages?.error) {
          messenger.showError('Sorry but something went wrong', errorMessage)
        }
      } else {
        console.warn(
          'Failed to ' +
          requestName +
          '. Server response: ' +
          response.statusText +
          (responseMessages?.error
            ? ' - ' + responseMessages.error
            : ''
          )
        )
      }
      return [false, responseData]
    }
    return [true, responseData]
  }

  processError = (error, requestName) => {
    console.error('Failed to ' + requestName + '. ' + error)
    return [false, null]
  }
}

export const api = new Api()


// INITIAL DATA
export const getDefaultData = async () => {
  const [success, response] =
    window.location.pathname.startsWith('/create-password/') ?
      await api.get('auth' + window.location.pathname)
      :
      await api.get('api/portal/' + window.eventops.app + window.location.pathname)
  if (response) {
    const defaultData = {}
    if ('company' in response) {
      defaultData.company = response.company
    }
    if ('account' in response) {
      defaultData.account = response.account
    }
    if ('links' in response) {
      defaultData.links = response.links
    }
    if ('data' in response) {
      defaultData.data = response.data
    }
    if ('permissions' in response) {
      defaultData.permissions = response.permissions
    }
    if ('style' in response) {
      defaultData.style = response.style
    }
    return defaultData
  }
  return {}
}
