import React from 'react'
import BaseField from './BaseField'


export default function FieldCheckbox(props) {
  /* Input Checkbox
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * values = [{ label: <string>, value: <string>, required: <boolean> }] Required
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * isInLine = <boolean> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */
  const selectedValues = props.value ? props.value?.split("||").filter(v => v !== "") : []
  const required = props.required && props.value === ""

  // FUNCTIONS
  const onChange = event => {
    const clickedIndex = selectedValues.indexOf(event.target.value)
    if (clickedIndex !== -1) {
      selectedValues?.splice(clickedIndex, 1)
    } else {
      selectedValues?.push(event.target.value)
    }
    props.onChange({ target: { value: selectedValues?.join("||") } })
  }

  // VALIDATION
  // Check if state value is a valid option in values
  React.useEffect(() => {
    if (props.values !== null && props.value !== "") {
      let values = []
      let changed = false
      for (let item of (props.value?.split("||") || [])) {
        if (props.values?.some(v => v.value === item)) {
          values.push(item)
        } else {
          changed = true
        }
      }
      if (changed) {
        props.onChange({ target: { value: values.join("||") } })
      }
    }
  }, [props.value, JSON.stringify(props.values)])

  // RENDER
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        props.values?.map((option, key) => (
          <div key={key} className={"form-check" + (props.isInLine ? " form-check-inline ml-3 mr-0" : "")}>
            <label className="form-check-label custom-field-label">
              <input
                className={"form-check-input custom-field-input" + (
                  props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
                )}
                name={props.name}
                type="checkbox"
                value={"" + option.value}
                checked={selectedValues.includes("" + option.value)}
                required={(required || option.required) && props.valid === false}
                onChange={onChange}
                onBlur={props.onBlur}
                disabled={option.disabled || props.readOnly}
              />
              {"" + option.label}{option.required ? <span className="text-danger"> *</span> : null}
            </label>
          </div>
        ))
      }
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
