import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import api from 'libs/api'
import { getRandomColor, isColorLight } from '@eventops/utils'
// Components
import BaseField from './BaseField'
import { Spinner } from 'components/spinner'
import Select from 'antd/es/select'
import AntdTag from 'antd/es/tag'
const { Option } = Select


export default function FieldSearch(props) {
  /*
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onChangeColors = <function()> Optional
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * multiple = <boolean> Optional
   * api = {
   *   method = <string> Required
   *   url = <string> Required
   *   authorization = <string> Optional
   *   request = <Object> Optional
   *   query = <string> Optional
   *   response = {
   *     array = [<string>] Required
   *     label = [<string>] Required
   *     value = [<string>] Required
   *   }
   * }
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   * tagRender = <boolean> Optional (Default = false. Only works if multiple)
   * initialColors = <object> Optional
   */
  const defaultColor = "#f5f5f5"
  const [options, setOptions] = useState()
  const [rawOptions, setRawOptions] = useState({})
  const [optionsColors, setOptionsColors] = useState(props.initialColors || {})
  const [search, setSearch] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const isDisabled = (props.disabled || props.readOnly) && true

  // Functions
  const onSearch = value => setSearch(value)

  const onChange = value => {
    if (value === undefined) value = ""
    props.onChange?.({ target: { value } }, Array.isArray(value) ? value.map(v => rawOptions[v]) : rawOptions[value])
  }

  const onClear = () => setSearch("")

  const onBlur = () => setSearch("") && props.onBlur?.()

  // Initialization/APIs
  const searchQuery = !search ? "" :
    `&search=${search.split(" ").join("&search=")}`

  const valueInQuery = props.api?.response?.value?.length ? props.api.response.value.join("__") : "uuid"
  const valuesQuery = (!props.value || !props.value.length) ? "" :
    `&value_in=${valueInQuery}&${valueInQuery}=${Array.isArray(props.value) ? props.value.join(`&${valueInQuery}=`) : props.value}`

  const getValues = () => {
    // Cancel listener
    let wasCancelled = false
    const returningObj = {
      cancel: function () {
        wasCancelled = true
      }
    }
    // Set is loading flag
    setIsLoading(true)
    // Request values locally if user token is not required
    if (!props.api.authorization || !props.api.authorization.includes('{user_token}')) {
      const apiUrl = (props.api.url.startsWith("http") ? "" : api.url) + props.api.url
      const apiProps = {
        method: props.api.method || 'GET',
        mode: 'cors',
        ...(props.api.url.startsWith("http") ? {} : {
          credentials: 'include',
          referrerPolicy: 'origin',
        }),
        headers: {
          'Content-Type': 'application/json',
          ...(props.api.authorization ? {
            Authorization: props.api.authorization
          } : {})
        },
        body: props.api.method === "GET" ? undefined : JSON.stringify(props.api.request)
      }
      fetch(apiUrl + `?page_size=50${props.api.query ? ("&" + props.api.query) : ""}${searchQuery}${valuesQuery}`, apiProps)
        .then(async (response) => {
          // Check if was not cancelled
          if (wasCancelled) {
            return
          }
          // If request failed:
          if (response.status !== 200) {
            console.error('Failed to fetch data for field. Server response: ' + response.statusText)
            setIsLoading(false)
            return
          }
          // If not, get response payload
          const responseJson = await response.json()
          // Get list
          const objects = props.api.response.array.reduce((objects, index) => objects[index], responseJson)
          // Check list
          if (!Array.isArray(objects)) {
            console.error(
              'Failed to fetch data for field. Could not get an array from response: ' + JSON.stringify(objects)
            )
            setIsLoading(false)
            return
          }
          // Get values
          const values = objects.map((object) => ({
            label: props.api.response.label.reduce((label, index) => index.split("|").map(indexN => label[indexN]).join(" - "), object),
            value: props.api.response.value.reduce((value, index) => value[index], object),
            raw: [props.api.response.value.reduce((value, index) => value[index], object), object]
          })) // TODO: Filter duplicated values
          // Check values
          if (values[0] && (values[0].label instanceof Object || values[0].value instanceof Object)) {
            console.error(
              'Failed to fetch data for field. Could not get a valid value from response: ' + JSON.stringify(values[0])
            )
            setIsLoading(false)
            return
          }
          // Check if was not cancelled
          if (wasCancelled) {
            return
          }
          // Save results
          setIsLoading(false)

          const items = values.map((data, key) => (
            <Option value={data.value} label={data.label} key={data.value}>
              {data.label}
            </Option>
          ))
          setOptions(items)
          if (props.tagRender) {
            setOptionsColors(prevOptionsColors => {
              for (const v of values) {
                prevOptionsColors[v.value] = v.color || prevOptionsColors[v.value] || getRandomColor()
              }
              return { ...prevOptionsColors }
            })
          }
          // Fill Raw Option
          setRawOptions(prev => ({ ...prev, ...Object.fromEntries(values.map(v => v.raw)) }))
        })
        .catch((err) => console.error(err))
    }
    // Returning canceling function
    return returningObj
  }

  useEffect(() => {
    if (props.api && props.api.url && props.api.response) {
      if (/^(https:\/\/|api\/)[\S]+$/.test(props.api.url)) {
        let getValuesRequest
        const delayedGetValues = setTimeout(() => {
          getValuesRequest = getValues()
        }, 500)
        return () => {
          getValuesRequest?.cancel()
          clearTimeout(delayedGetValues)
        }
      } else {
        console.error("Failed to fetch data for field. '" + props.api.url + "' is not a valid URL.")
        setIsLoading(false)
      }
    } else {
      console.error('Failed to fetch data for field. Could not find necessary API data.')
      setIsLoading(false)
    }
  }, [JSON.stringify(props.api), searchQuery, valuesQuery])

  useEffect(() => {
    if (typeof props.onChangeColors === "function") {
      props.onChangeColors(optionsColors)
    }
  }, [optionsColors])

  // Render
  const tagRender = ({ label, value, closable, onClose }) => {
    const color = optionsColors[value] || defaultColor
    return <AntdTag
      className={isColorLight(color) ? "text-black" : "text-white"}
      color={color}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </AntdTag>
  }

  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={isLoading && !options ?
        <div className="input-loading">
          <input
            className="form-control custom-field-input"
            name={props.name}
            type="text"
            value="Loading..."
            readOnly={true}
          />
          <Spinner />
        </div>
        :
        <Select
          ref={props.inputRef}
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          mode={props.multiple ? "multiple" : undefined}
          disabled={isDisabled}
          readOnly={props.readOnly}
          autoFocus={props.autoFocus}
          suffixIcon={isLoading ? <Spinner /> : undefined}
          notFoundContent={isLoading ? "Loading..." : undefined}
          placeholder={props.readOnly ? "" : props.placeholder || "Type to search..."}
          showSearch
          allowClear={props.allowClear !== false}
          onChange={onChange}
          filterOption={false}
          onSearch={onSearch}
          onBlur={onBlur}
          onClear={onClear}
          value={props.value !== "" ? props.value : undefined}
          showArrow={!isDisabled}
          tagRender={props.tagRender ? tagRender : undefined}
        >
          {options}
        </Select>
      }
      prepend={props.prepend}
      append={props.append}
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}

FieldSearch.propTypes = {
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  validMessage: PropTypes.string,
  invalidMessage: PropTypes.string,
  help: PropTypes.string,
  helpAboveInput: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  input: PropTypes.element,
  api: PropTypes.shape({
    method: PropTypes.string,
    url: PropTypes.string.isRequired,
    authorization: PropTypes.string,
    request: PropTypes.object,
    response: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  }).isRequired
}
