import Content from "components/layout/Content";

export default function InvitationRejected(props)
{
  const title = "Invitation Rejected"
  return(<Content title={title} maxWidth>
  <main className="app-layout-content custom-main content-centered-invitation">
    <h3>Thank you for your response!</h3>
    <div className="mt-2">If you have changed your mind, you can check your email to revert this action.</div>
   </main>
 </Content>)
}