import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import BaseButton from './BaseButton'


export default function ButtonSuccess({
  className,
  size,
  title,
  text,
  disabled,
  loading,
  onClick,
}) {

  return (
    <BaseButton
      className={classNames("btn-success custom-btn-success", className)}
      type="text"
      size={size}
      title={title}
      text={text}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  )
}

ButtonSuccess.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf([
    'large',
    'middle',
    'small'
  ]),
  title: PropTypes.string,
  text: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
}
