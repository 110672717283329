import React from 'react'
import BaseField from './BaseField'
import { isEqual } from 'libs/utils'


export default function FieldRadio(props) {
  /* Input Radio
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * values = [{ label: <string>, value: <string> }] Required
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * isInLine = <boolean> Optional
   * horizontal = <boolean> Optional
   * horizontalLabelSize = <int [1-23]> Optional (Default = 6)
   */
  const [savedValues, setSavedValues] = React.useState(props.values)

  // VALIDATION
  // Check if state value is a valid option in values
  const validateValue = () => {
    if (props.values !== null && props.value !== "" && !props.values.some(v => v.value === props.value)) {
      props.onChange({ target: { value: "" } })
    }
  }
  React.useEffect(() => {
    if (!isEqual(props.values, savedValues)) {
      validateValue()
      setSavedValues(props.values)
    }
  }, [props.values])
  React.useEffect(validateValue, [props.value])

  // FUNCTIONS
  const handleChange = (event) => {
    const selectedOption = props.values[props.values.findIndex(v => ("" + v.value) === event.target.value)]
    props.onChange({ target: { value: selectedOption ? selectedOption.value : "" } })
  }

  // RENDER
  return (
    <BaseField
      className={props.className}
      labelClassName={props.labelClassName}
      inputClassName={props.inputClassName}
      label={props.label}
      input={
        props.values?.map((option, key) => (
          <div key={key} className={"form-check" + (props.isInLine ? " form-check-inline ml-3 mr-0" : "")}>
            <label className="form-check-label custom-field-label">
              <input
                className={"form-check-input custom-field-input" + (
                  props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
                )}
                name={props.name}
                type="radio"
                value={"" + option.value}
                checked={props.value === option.value}
                required={props.required && props.valid === false}
                onChange={handleChange}
                onBlur={props.onBlur}
                disabled={option.disabled || props.readOnly}
              />
              {"" + option.label}
            </label>
          </div>
        ))
      }
      disabled={props.disabled}
      readOnly={props.readOnly}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      horizontal={props.horizontal}
      horizontalLabelSize={props.horizontalLabelSize}
    />
  )
}
