import { isString } from './dataCheckers'

// Numbers
export function numberToString(numberValue, twoDigits = false) {
  const number = Number(numberValue)
  if (!twoDigits && Number.isInteger(number)) {
    return number.toLocaleString()
  }
  if (Number.isFinite(number)) {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  return "-"
}

// Dates
const timeFormat = { hour: "2-digit", minute: "2-digit" }
const dateFormat = { day: "2-digit", month: "long", year: "numeric" }
export function datetimeToString(datetimeValue) {
  return datetimeValue ? new Date(datetimeValue).toLocaleString([], dateFormat) + " @ " + new Date(datetimeValue).toLocaleString([], timeFormat) : "-"
}

export function dateToString(datetimeValue) {
  return datetimeValue ? new Date(datetimeValue).toLocaleString([], dateFormat) : "-"
}

export function timeToString(datetimeValue) {
  return datetimeValue ? new Date(datetimeValue).toLocaleString([], timeFormat) : "-"
}

// Strings
export function capitalizeString(value) {
  return isString(value) ? value[0].toUpperCase() + value.substring(1) : ""
}

