import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Form from './form'
import Widgets from './widgets'
import NotFound from 'apps/notfound'
import SelectWidget from './widgets/SelectWidget'
import { InvitationAccepted, InvitationRejected } from './invitation'


export default function Forms(props) {

  return (
    <Switch>
      <Route exact path="/:type/:form">
        <Form {...props} />
      </Route>
      <Route exact path="/widgets/:eventId">
        <SelectWidget {...props} />
      </Route>
      <Route exact path="/widgets/:widgetName/:eventId">
        <Widgets {...props} />
      </Route>
      <Route exact path="/accepted/:event_id/:to_email">
        <InvitationAccepted {...props} />
      </Route>
      <Route exact path="/declined/:event_id/:to_email">
        <InvitationRejected {...props} />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}
